import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['toolbar', 'footer']

  connect () {
    if (this.hasFooterTarget) {
      this.observer = new IntersectionObserver(this.intersected.bind(this), {
        threshold: 0.75
      })
      this.observer.observe(this.footerTarget)
    }
  }

  disconnect () {
    if (this.hasFooterTarget) this.observer.disconnect()
  }

  intersected (entries) {
    requestAnimationFrame(() => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.setToolbarMargin(this.height + this.offset)
        } else {
          this.setToolbarMargin(0)
        }
      })
    })
  }

  setToolbarMargin (offset) {
    this.toolbarTarget.style.marginBottom = `${offset}px`
  }

  get height () {
    return this.footerTarget.offsetHeight
  }

  get offset () {
    return Number(this.footerTarget.dataset.footerOffset)
  }
}
