export default {
  date (val) {
    if (!/\d{2}\/\d{2}\/\d{4}/.test(val)) return null

    const [day, month, year] = val.split('/').map((i) => parseInt(i))
    const date = new Date(year, month - 1, day)

    if (date.getFullYear() !== year || date.getMonth() !== month - 1 || date.getDate() !== day) return null

    const userTimezoneOffset = date.getTimezoneOffset() * 60000
    return new Date(date.getTime() - userTimezoneOffset)
  },

  credit_card_month_year (val) {
    if (!/\d{2}\/\d{2}/.test(val)) return null

    const [month, year] = val.split('/').map((i) => parseInt(i))
    const date = new Date(year + 2000, month, 0)

    if (!(date instanceof Date && !isNaN(date) && date.getMonth() + 1 === month && date.getTime() >= Date.now())) return null

    return date
  }
}
