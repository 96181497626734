import { Controller } from '@hotwired/stimulus'
import Util from '../../lib/util'

export default class extends Controller {
  static targets = ['item']
  static classes = ['closeAnimation']

  checked (e) {
    const currentItem = e.currentTarget.closest('[data-checklist-target]')
    const index = this.itemTargets.findIndex(item => item === currentItem)
    const nextItem = index >= 0 ? this.itemTargets[index + 1] : null

    currentItem.open = false
    nextItem.open = true
    Util.scrollToElement(nextItem, -10)
  }
}
