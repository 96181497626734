import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['master', 'checkbox', 'actionOptions']
  static classes = ['action']
  static values = { maxSelections: Number }

  connect () {
    this.setObserver()
  }

  setObserver () {
    this.checkboxTargets.forEach((target) => this.setEventListeners(target))
  }

  setEventListeners (target) {
    target.addEventListener('change', event => {
      this.updateActionTargets()
    })

    if (this.hasMaxSelections()) {
      target.addEventListener('change', event => {
        this.ensureMaxSelections(event)
      })
    }
  }

  toggleAll () {
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = this.masterTarget.checked
    })
    this.setAction()
  }

  toggleMaster () {
    if (this.checkboxTargets.length > 0 && this.checkboxTargets.length === this.selectedCheckboxes.length) {
      this.masterTarget.checked = true
    } else {
      this.masterTarget.checked = false
    }
    this.setAction()
  }

  hasMaxSelections () {
    return Boolean(this.maxSelectionsValue)
  }

  ensureMaxSelections (event) {
    if (this.exceededMaxSelections()) {
      event.target.checked = false
    }
  }

  exceededMaxSelections () {
    return this.selectedCheckboxes.length > this.maxSelectionsValue
  }

  updateActionTargets () {
    const shouldAddClass = this.actionClass === 'disabled'

    if (this.hasSelectedCheckboxes) {
      this.toggleActionTargetsClassTo(!shouldAddClass)
    } else {
      this.toggleActionTargetsClassTo(shouldAddClass)
    }
  }

  toggleActionTargetsClassTo (value) {
    this.actionOptionsTargets.forEach((target) => target.classList.toggle(this.actionClass, value))
  }

  // ============================================================================
  // INTERNAL USAGE
  // ============================================================================
  setAction () {
    const shouldAddClass = this.actionClass === 'disabled'

    if (this.hasSelectedCheckboxes) {
      this.actionOptionsTargets.forEach((target) => { target.classList.toggle(this.actionClass, !shouldAddClass) })
    } else {
      this.actionOptionsTargets.forEach((target) => { target.classList.toggle(this.actionClass, shouldAddClass) })
    }
  }

  get selectedCheckboxes () {
    return this.checkboxTargets.filter((checkbox) => checkbox.checked)
  }

  get hasSelectedCheckboxes () {
    return this.selectedCheckboxes.length > 0
  }
}
