import { Controller } from '@hotwired/stimulus'
import Dom from '../../lib/dom'
import Text from '../../lib/text'

export default class extends Controller {
  static targets = ['name', 'number', 'expiration', 'cvv', 'hash', 'brand', 'cardError', 'apiError']
  static values = { apiKey: String, paymentId: String }

  /* ==== Life Cycle ==== */
  initialize () {
    var checkExist = setInterval(() => {
      if (typeof Mercadopago !== 'undefined') {
        Mercadopago.setPublishableKey(this.apiKeyValue)
        clearInterval(checkExist)
      }
    }, 100)
  }

  connect () {
    this.blockSensitiveParameters()
  }

  /* ==== Actions ==== */
  generate (e) {
    if (!this.validate()) return
    Dom.fire(e.target, 'generating', null, { bubbles: true })

    try {
      Mercadopago.getPaymentMethod({
        'bin': this.numberTarget.value.replace(/[ .-]/g, '').slice(0, 6)
      }, (status, response) => {
        this.setPaymentMethod(e, status, response)
      })

      Mercadopago.createToken(this.element, (status, response) => {
        this.charge(e, status, response)
      })
    } catch (error) {
      this.handleError(e, error)
    }
  }

  /* ==== Support ==== */
  setPaymentMethod (e, status, response) {
    try {
      this.brandTarget.value = status === 200 ? response[0] && response[0].id : null
    } catch (error) {
      this.handleError(e, error)
    }
  }

  charge (e, status, response) {
    try {
      window.Mercadopago.clearSession()

      if (status !== 200 && status !== 201) {
        this.handleError(e, new Error(`${response.message}. ${response.cause.map(cause => cause.description).join('|')}`))
      } else {
        this.hashTarget.value = response.id
        this.blockSensitiveParameters()

        document.querySelector('#payment-loading-text').textContent = 'Validado. Processando pagamento...'
        Dom.fire(e.target.closest('form'), 'submit', null, { bubbles: true })
      }
    } catch (error) {
      this.handleError(e, error)
    }
  }

  handleError (e, error) {
    if (error.message && error.message.indexOf('locked resource') !== -1) {
      this.apiErrorTarget.classList.remove('hidden')
    } else {
      this.cardErrorTarget.classList.remove('hidden')
    }

    Dom.fire(e.target, 'rejected', null, { bubbles: true })

    throw error
  }

  validate () {
    let valid = true
    valid = valid & this.validateField(this.nameTarget)
    valid = valid & this.validateField(this.numberTarget, 19)
    valid = valid & this.validateField(this.expirationTarget)
    valid = valid & this.validateField(this.cvvTarget)
    return valid
  }

  validateField (input, length) {
    let valid = true
    valid = valid && Text.isPresent(input.value)
    valid = valid && (!length || input.value.length === length)

    if (!valid) {
      input.classList.add('field_with_errors')
      input.nextElementSibling.classList.add('field_with_errors')
    }

    return valid
  }

  blockSensitiveParameters () {
    this.nameTarget.removeAttribute('name')
    this.numberTarget.removeAttribute('name')
    this.expirationTarget.removeAttribute('name')
    this.cvvTarget.removeAttribute('name')
  }
}
