import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    this.bootstrap().then(Invitation => {
      Invitation.start()
    })
  }

  // Only load Invitations when necessary
  async bootstrap () {
    const { default: Invitation } = await import(/* webpackChunkName: "fudgeball_invitations" */ '../../../elements/invitations')
    return Invitation
  }
}
