import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['submit', 'submitButton', 'warning']

  checkIfValueCanBeWithdrawn (e) {
    // reset everything
    const withdrawalAmount = parseFloat(e.target.value.replace('.', '').replace(',', '.'))
    const availableAmount = parseFloat(e.target.dataset.availableAmount)
    e.target.classList.remove('txt-error')

    // check if value is present
    if (e.target.value !== '') {
      this.submitTarget.classList.remove('hidden')
    } else {
      this.submitTarget.classList.add('hidden')
    }

    // check if value can be withdrawn
    if (withdrawalAmount > availableAmount) {
      e.target.classList.add('txt-error')
      this.warningTarget.classList.remove('hidden')
      this.submitButtonTarget.disabled = true
    } else {
      this.warningTarget.classList.add('hidden')
      this.submitButtonTarget.disabled = false
    }
  }
}
