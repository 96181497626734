import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['bar', 'section', 'item', 'dot']

  connect () {
    if (!this.hasBarTarget) return

    this.observer = new IntersectionObserver(this.toggle.bind(this), { threshold: 0.5 })
    this.sectionTargets.forEach((section) => {
      this.observer.observe(section)
    })
  }

  disconnect () {
    if (!this.hasBarTarget) return

    this.sectionTargets.forEach((section) => {
      this.observer.unobserve(section)
    })
  }

  toggle (entries, observer) {
    entries.forEach((entry) => {
      const item = this.itemTargets[this.sectionIndex(entry)]
      const dot = this.dotTargets[this.sectionIndex(entry)]

      if (item !== undefined) item.classList.toggle('navigation__item--active', entry.isIntersecting)
      if (dot !== undefined) dot.classList.toggle('navigation__dot--active', entry.isIntersecting)
    })
  }

  sectionIndex (entry) {
    return entry.target.dataset.navigationIndex
  }
}
