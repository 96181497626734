import { Controller } from '@hotwired/stimulus'
import Macy from 'macy'

export default class extends Controller {
  connect () {
    this.macy = Macy({
      container: this.element,
      margin: parseInt(this.data.get('margin')) || 0,
      columns: this.data.get('columns') || 4,
      breakAt: {
        640: {
          columns: this.data.get('mobile-columns') || 2,
          margin: parseInt(this.data.get('mobile-margin')) || 0
        }
      }
    })
  }
}
