import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static player

  connect () {
    this.retry_counter = 0
    this.createAPI()
  }

  preparePlayer () {
    if (this.retry_counter < 10) {
      if (typeof YT === 'undefined' || typeof YT.Player === 'undefined') {
        console.log('YT not ready yet...')
        this.retry_counter++
        setTimeout(() => {
          this.preparePlayer()
        }, 200)
      } else {
        console.log('YT is Ready')
        this.createPlayer()
      }
    } else {
      console.log('YT is offline')
    }
  }

  createPlayer () {
    this.player = new YT.Player('youtube-player', {
      videoId: this.data.get('songId'),
      playerVars: {
        loop: 1,
        enablejsapi: 1
      },
      events: {
        'onStateChange': this.stateChanged
      }
    })
    this.showButtons()
  }

  createAPI () {
    const tag = document.createElement('script')
    tag.src = 'https://www.youtube.com/iframe_api'

    const firstScriptTag = document.getElementsByTagName('script')[0]
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)

    tag.onload = this.preparePlayer()
  }

  play () {
    this.player.playVideo()
    this.player.unMute()
  }

  pause () {
    this.player.pauseVideo()
  }

  showButtons () {
    this.element.classList.remove('hidden')
  }

  stateChanged (event) {
    const playButton = document.querySelector('#play-button')
    const pauseButton = document.querySelector('#pause-button')

    if (event.data === YT.PlayerState.PLAYING) {
      playButton.classList.add('hidden')
      pauseButton.classList.remove('hidden')
    } else {
      playButton.classList.remove('hidden')
      pauseButton.classList.add('hidden')
    }
  }
}
