// Exception logging
// import Sentry from './lib/sentry'

// Rails
import '@hotwired/turbo-rails'

// App
import './controllers/websites'
import Polyfills from './lib/polyfills'
import MinimalAnalytics from './lib/minimal_analytics'
import ImageLazyLoading from './lib/image_lazy_loading'

//  Start everything that needs to be started
// Sentry.start()
Polyfills.start()
MinimalAnalytics.start()
ImageLazyLoading.start()
