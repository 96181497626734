import Trix from '../rails/trix'
import '../rails/actiontext'

const Clipboard = {
  start () {
    document.addEventListener('trix-initialize', () => {
      this.setToolbar()
      this.setLanguage()
      this.setAttachmentsConfig()
    })
  },

  setToolbar () {
    const toolbars = document.getElementsByTagName('trix-toolbar')
    const { lang } = Trix.config

    toolbars.forEach(toolbar => {
      toolbar.innerHTML = `<div class="text-editor-button-groups">
        <span class="text-editor-button-group festalab-tools">
            <button type="button" class="festalab-tools-button festalab-tools-button--bold" data-trix-attribute="bold" data-key="b" title="${lang.bold}"></button>
            <button type="button" class="festalab-tools-button festalab-tools-button--italic" data-trix-attribute="italic" data-key="i" title="${lang.italic}"></button>
            <button type="button" class="festalab-tools-button festalab-tools-button--photo-upload" data-trix-action="attachFiles" title="${lang.attachFiles}"></button>
            <button type="button" class="festalab-tools-button festalab-tools-button--undo" data-trix-action="undo" data-key="z" title="${lang.undo}"></button>
            <button type="button" class="festalab-tools-button festalab-tools-button--redo" data-trix-action="redo" data-key="shift+z" title="${lang.redo}"></button>
            <button type="button" class="festalab-tools-button festalab-tools-button--bullets" data-trix-attribute="bullet" data-key="" tab-index="-1" title="${lang.bullets}"></button>
        </span>
      </div>`
    })
  },

  setAttachmentsConfig () {
    const { attachments } = Trix.config

    attachments.preview.caption.name = false
    attachments.preview.caption.size = false
  },

  setLanguage () {
    const { lang } = Trix.config

    lang.bold = 'Negrito'
    lang.italic = 'Itálico'
    lang.attachFiles = 'Adicionar fotos'
    lang.undo = 'Desfazer'
    lang.redo = 'Refazer'
    lang.captionPlaceholder = 'Adicionar legenda...'
    lang.bullets = 'Lista de itens'
  }
}

export default Clipboard
