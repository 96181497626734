import { Controller } from '@hotwired/stimulus'

import loadImage from 'blueimp-load-image/js/load-image'
import Cropper from 'cropperjs'

export default class extends Controller {
  static targets = [
    'mobileX', 'mobileY', 'mobileRotate', 'mobileWidth', 'mobileHeight',
    'desktopX', 'desktopY', 'desktopRotate', 'desktopWidth', 'desktopHeight',
    'file', 'photo', 'uploadWrapper', 'error', 'chooseButton', 'restartButton',
    'nextButton', 'saveButton', 'controls', 'subHeaderMobile', 'subHeaderDesktop'
  ]

  connect () {
    this.data.set('photo', this.photoTarget.src)
    this.toggleElements('initialize')
  }

  change (e) {
    this.toggleElement(this.uploadWrapperTarget, true)
    const file = e.target.files[0]
    if (!!file && (/image\/(jpg|jpeg|png|webp|gif)/.test(file.type))) {
      this.toggleElements('loading')
      loadImage(file, (img) => {
        this.photoTarget.src = img.src
        this.initMobileEditor()
      }, { noRevoke: true })
    } else {
      this.toggleElements('error')
      e.target.value = ''
    }
  }

  restart () {
    this.cropper?.destroy()
    this.cropper = null
    this.fileTarget.value = ''
    this.photoTarget.src = this.data.get('photo')
    this.toggleElements('restart')
  }

  edit () {
    this.toggleElements('loading')
    this.initMobileEditor()
  }

  initMobileEditor () {
    const [aspectX, aspectY] = this.hasCustomAspectRatio() ? this.customAspectRatio() : [1, 1.20]
    this.cropper = new Cropper(this.photoTarget, {
      aspectRatio: (aspectX / aspectY),
      viewMode: 1,
      autoCropArea: 1,
      cropBoxResizable: false,
      cropBoxMovable: false,
      dragMode: 'move',
      crop: (e) => {
        if (!this.exifRotate) this.exifRotate = e.detail.rotate

        this.mobileXTarget.value = e.detail.x
        this.mobileYTarget.value = e.detail.y
        this.mobileHeightTarget.value = e.detail.height
        this.mobileWidthTarget.value = e.detail.width

        // iOS is a fucking moron and all photos are rotated by default. Compensate user rotation by it
        this.mobileRotateTarget.value = e.detail.rotate - this.exifRotate
      },
      ready: () => {
        this.toggleElements('editing')
      }
    })
  }

  initDesktopEditor () {
    const [aspectX, aspectY] = (this.hasCustomAspectRatio() ? this.customAspectRatio('Desktop') : [16, 9])
    this.cropper.destroy()
    this.cropper = new Cropper(this.photoTarget, {
      aspectRatio: (aspectX / aspectY),
      viewMode: 1,
      autoCropArea: 1,
      cropBoxResizable: false,
      cropBoxMovable: false,
      dragMode: 'move',
      crop: (e) => {
        if (!this.exifRotate) this.exifRotate = e.detail.rotate

        this.desktopXTarget.value = e.detail.x
        this.desktopYTarget.value = e.detail.y
        this.desktopHeightTarget.value = e.detail.height
        this.desktopWidthTarget.value = e.detail.width

        // iOS is a fucking moron and all photos are rotated by default. Compensate user rotation by it
        this.desktopRotateTarget.value = e.detail.rotate - this.exifRotate
      },
      ready: () => {
        this.toggleElements('next')
      }
    })
  }

  nextDevice () {
    this.toggleElements('loading')
    this.initDesktopEditor()
  }

  hasPhoto () {
    return this.data.get('hasPhoto') === 'true'
  }

  hasCustomAspectRatio () {
    return this.data.get('customAspectRatio') === 'true' || false
  }

  customAspectRatio (variant = '') {
    const aspectRatio = this.data.get(['aspectRatio', variant].join('')) || false

    if (aspectRatio) return aspectRatio.split('|').map(ratio => Number(ratio))
  }

  toggleElement (element, force, klass = 'hidden') {
    element.classList.toggle(klass, !force)
  }

  toggleElements (status) {
    switch (status) {
      case 'loading':
        this.toggleElement(this.chooseButtonTarget, false)
        this.uploadWrapperTarget.classList.add('inactive')
        if (this.hasControlsTarget) this.toggleElement(this.controlsTarget, false)
        break
      case 'editing':
        this.uploadWrapperTarget.classList.remove('inactive')
        this.toggleElement(this.restartButtonTarget, true)
        this.toggleElement(this.subHeaderMobileTarget, true)
        this.toggleElement(this.nextButtonTarget, true)
        break
      case 'next':
        this.uploadWrapperTarget.classList.remove('inactive')
        this.toggleElement(this.subHeaderDesktopTarget, true)
        this.toggleElement(this.subHeaderMobileTarget, false)
        this.toggleElement(this.saveButtonTarget, true)
        this.toggleElement(this.nextButtonTarget, false)
        break
      case 'error':
        this.toggleElement(this.chooseButtonTarget, false)
        this.toggleElement(this.uploadWrapperTarget, false)
        this.toggleElement(this.errorTarget, true)
        if (this.hasControlsTarget) this.toggleElement(this.controlsTarget, false)
        break
      default:
        this.toggleElement(this.chooseButtonTarget, !this.hasPhoto())
        this.toggleElement(this.uploadWrapperTarget, this.hasPhoto(), 'inactive')
        this.toggleElement(this.errorTarget, false)
        this.toggleElement(this.subHeaderMobileTarget, false)
        this.toggleElement(this.subHeaderDesktopTarget, false)
        this.toggleElement(this.restartButtonTarget, false)
        this.toggleElement(this.nextButtonTarget, false)
        this.toggleElement(this.saveButtonTarget, false)
        if (this.hasControlsTarget) this.toggleElement(this.controlsTarget, this.hasPhoto())
    }
  }
}
