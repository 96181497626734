import Lozad from 'lozad'

const ImageLazyLoading = {
  start () {
    document.addEventListener('turbo:load', () => {
      const observer = Lozad()
      observer.observe()
    })

    document.addEventListener('turbo:frame-render', () => {
      const observer = Lozad()
      observer.observe()
    })

    document.addEventListener('turbo:render', () => {
      const observer = Lozad()
      observer.observe()
    })

    document.addEventListener('ajax:done', () => {
      const observer = Lozad()
      observer.observe()
    })
  }
}

export default ImageLazyLoading
