const data = {
  container: null,
  element: null,
  groupMode: false
}

const Resizer = {
  allFonts (delay) {
    delay = delay || 0

    // Force a delay to ensure resizing is done after reflow
    setTimeout(function () {
      document.querySelectorAll('[data-allow-font-resize]').forEach(function (element) {
        if (element.querySelector(':not(br)')) {
          Resizer.font(element.lastElementChild)
        } else {
          Resizer.font(element)
        }
      })
    }, delay)
  },

  /*
   * Given an element containing text, resizes the font of the element (and its siblings) so that it fits within the
   * container. The container element must have either a height value set, or a min-height AND max-height set for this
   * to work properly.
   */
  font (el) {
    data.container = el.parentNode
    data.element = el

    if (Resizer.allowFontResize()) {
      Resizer.child()
    } else if (Resizer.allowFontGroupResize()) {
      Resizer.children()
    }
  },

  child () {
    data.groupMode = false

    Resizer.prepareForResize()
    Resizer.increaseFontSizeToMax()
    Resizer.reduceFontSizeToFit()
    Resizer.applyResizingToGroup()
  },

  children () {
    data.groupMode = true
    data.element = data.container.lastElementChild

    Resizer.prepareForResize()
    Resizer.increaseFontSizeToMax()
    Resizer.reduceFontSizeToFit()
  },

  applyResizingToGroup () {
    const group = data.element.dataset.resizerGroup
    if (!group) return

    const elements = Array.prototype.slice.call(document.querySelectorAll(`[data-resizer-group="${group}"]`))
    let fontSize = Resizer.getCurrentFontSize()

    const fontSizes = elements.map(element => {
      data.element = element
      if (Resizer.getCurrentFontSize() < fontSize) Resizer.increaseFontSizeToMax()
      return Resizer.getCurrentFontSize()
    }).filter(fontSize => !isNaN(fontSize))

    fontSize = Math.min(...fontSizes)
    elements.forEach(element => {
      data.element = element
      Resizer.setCurrentFontSize(fontSize)
    })
  },

  reduceFontSizeToFit () {
    while (Resizer.isCurrentFontTooBig() && Resizer.getCurrentFontSize() > 1) {
      Resizer.setCurrentFontSize(Resizer.getCurrentFontSize() - 1)
    }
  },

  increaseFontSizeToMax () {
    while (!Resizer.isCurrentFontTooBig() && Resizer.getCurrentFontSize() < Resizer.getOriginalFontSize()) {
      Resizer.setCurrentFontSize(Resizer.getCurrentFontSize() + 1)
    }
  },

  isCurrentFontTooBig () {
    const offsetBottom = data.element.offsetTop < 0 || !data.groupMode ? data.element.offsetHeight : data.element.offsetTop + data.element.offsetHeight
    return (offsetBottom >= data.container.offsetHeight && data.container.offsetHeight >= Resizer.getMaxHeight() - 1) || Resizer.wordIsTooLong()
  },

  wordIsTooLong () {
    return data.element.offsetWidth > data.container.offsetWidth + 1
  },

  prepareForResize () {
    if (isNaN(Resizer.getOriginalFontSize())) {
      Resizer.setOriginalFontSize(Resizer.getFontSize())
    }

    if (isNaN(Resizer.getOriginalLineHeight())) {
      Resizer.setOriginalLineHeight(Resizer.getLineHeight())
    }

    if (isNaN(Resizer.getCurrentFontSize())) {
      Resizer.setCurrentFontSize(Resizer.getFontSize())
    }

    if (isNaN(Resizer.getMaxHeight())) {
      Resizer.setMaxHeight(Resizer.getAllowedHeight())
    }
  },

  setOriginalFontSize (val) {
    data.element.dataset.originalFontSize = val
  },

  getOriginalFontSize () {
    return parseInt(data.element.dataset.originalFontSize, 10)
  },

  setOriginalLineHeight (val) {
    data.element.dataset.originalLineHeight = val
  },

  getOriginalLineHeight () {
    return parseInt(data.element.dataset.originalLineHeight, 10)
  },

  setCurrentFontSize (val) {
    const lineHeight = this.getOriginalLineHeight() * val / this.getOriginalFontSize()
    const attributes = { 'font-size': `${val}px !important`, 'line-height': `${lineHeight}px !important` }

    if (data.groupMode) {
      data.container.querySelectorAll('div').forEach(function (element) {
        Resizer.mergeStyle(element, attributes)
      })
    } else {
      Resizer.mergeStyle(data.element, attributes)
    }

    data.element.dataset.currentFontSize = val
  },

  getCurrentFontSize () {
    return parseInt(data.element.dataset.currentFontSize, 10)
  },

  getMaxHeight () {
    return parseInt(data.container.dataset.maxHeight, 10)
  },

  setMaxHeight (val) {
    data.container.dataset.maxHeight = val
  },

  allowFontResize () {
    return data.element.dataset.allowFontResize !== undefined
  },

  allowFontGroupResize () {
    return data.container.dataset.allowFontResize !== undefined
  },

  getFontSize () {
    const size = window.getComputedStyle(data.element).fontSize
    return parseInt(size.replace(/[^\d.]/, ''), 10)
  },

  getLineHeight () {
    const lineHeight = window.getComputedStyle(data.element).lineHeight
    return parseInt(lineHeight.replace(/[^\d.]/, ''), 10)
  },

  getAllowedHeight () {
    const style = window.getComputedStyle(data.container)

    const maxHeight = parseInt(style.maxHeight.replace(/[^\d.]/, ''), 10)
    if (!isNaN(maxHeight)) return maxHeight

    return parseInt(style.height.replace(/[^\d.]/, ''), 10)
  },

  // This mess is needed because !important does not work unless we set the style as text
  mergeStyle (element, attributes) {
    Object.keys(attributes).forEach(key => {
      element.style[key] = ''
      element.style.cssText += `${key}: ${attributes[key]}`
    })
  }
}

export default Resizer
