import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['source', 'viewport', 'highlight']
  static values = { scale: Number }

  connect () {
    this.defineViewportBackgroundAttrs()
    this.highlightAttrsDefined = false;

    this.sourceTarget.addEventListener('mousemove', event => {
      this.sync(event)
    })
  }

  defineHighlightAttrs () {
    Object.assign(this.highlightTarget.style, {
      width: `${this.highlightSize.width}px`,
      height: `${this.highlightSize.height}px`,
      left: '0px',
      top: '0px'
    })
  }

  defineViewportBackgroundAttrs () {
    Object.assign(this.viewportTarget.style, {
      backgroundImage: `url(${this.imageUrl})`,
      backgroundPosition: '0 0',
      backgroundRepeat: 'no-repeat',
      backgroundSize: `${this.scaledWidth}px auto`
    })
  }

  sync (event) {
    const x = this.calculateLimits(event).x
    const y = this.calculateLimits(event).y

    this.syncHighlight({ x: x, y: y }, this.sourceBounds)
    this.syncViewport('x', this.sourceBounds, x)
    this.syncViewport('y', this.sourceBounds, y)
  }

  syncHighlight (position, bounds) {
    if (!this.highlightAttrsDefined) {
      this.defineHighlightAttrs()
      this.highlightAttrsDefined = true
    }

    const percentX = this.calculateSourcePosition('x', bounds, position.x)
    const percentY = this.calculateSourcePosition('y', bounds, position.y)

    const width = Number(this.highlightTarget.style.width.replace('px', ''))
    const height = Number(this.highlightTarget.style.height.replace('px', ''))

    const halfWidth = width / 2
    const halfHeight = height / 2

    const highlightX = percentX * this.sourceTarget.offsetWidth - halfWidth
    const highlightY = percentY * this.sourceTarget.offsetHeight - halfHeight

    const finalX = Math.min(Math.max(0, highlightX), this.sourceTarget.offsetWidth - width)
    const finalY = Math.min(Math.max(0, highlightY), this.sourceTarget.offsetHeight - height)

    Object.assign(this.highlightTarget.style, {
      transform: `translate(${finalX}px, ${finalY}px)`
    })
  }

  syncViewport (axis, bounds, clientPosition) {
    const percent = this.calculateSourcePosition(axis, bounds, clientPosition)
    const position = this.calculateViewportPosition(axis, percent)

    this.viewportTarget.style[`backgroundPosition${axis.toUpperCase()}`] = `${position}px`
  }

  calculateLimits (event) {
    const halfHighlightWidth = this.highlightSize.width / 2
    const halfHighlightHeight = this.highlightSize.height / 2

    const leftLimit = Math.round(this.sourceBounds.left + halfHighlightWidth)
    const topLimit = Math.round(this.sourceBounds.top + halfHighlightHeight)

    const rightLimit = Math.round(this.sourceBounds.right - halfHighlightWidth)
    const bottomLimit = Math.round(this.sourceBounds.bottom - halfHighlightHeight)

    const limitedClientX = Math.round(Math.min(Math.max(event.clientX, leftLimit), rightLimit))
    const limitedClientY = Math.round(Math.min(Math.max(event.clientY, topLimit), bottomLimit))

    return {
      x: limitedClientX,
      y: limitedClientY,
    }
  }

  calculateSourcePosition (axis, bounds, clientPosition) {
    const position = clientPosition - bounds[axis]
    const attrName = axis === 'x' ? 'width' : 'height'
    const percent = position / bounds[attrName]

    return percent
  }

  calculateViewportPosition (axis, percent) {
    const attrName = axis === 'x' ? 'Width' : 'Height'
    const viewportPosition = this[`scaled${attrName}`] * percent // ScaledWidth and ScaledHeight are the dimensions of the image in the viewport
    const halfViewportSize = this.viewportTarget.parentElement[`offset${attrName}`] / 2
    const centeredPosition = Math.round(viewportPosition - halfViewportSize) * -1

    return centeredPosition
  }

  get assetAspectRatio () {
    // eslint-disable-next-line no-undef
    const img = new Image()
    img.src = this.imageUrl
    const { naturalWidth, naturalHeight } = img
    return naturalWidth / naturalHeight
  }

  get highlightSize () {
    return {
      width: this.viewportTarget.offsetWidth / this.scaleValue,
      height: this.viewportTarget.offsetHeight / this.scaleValue
    }
  }

  get imageUrl () {
    return this.sourceTarget.dataset.src ?? this.sourceTarget.src
  }

  get scaledWidth () {
    return this.sourceSize.width * this.scaleValue
  }

  get scaledHeight () {
    return this.scaledWidth / this.assetAspectRatio
  }

  get sourceBounds () {
    return this.sourceTarget.getBoundingClientRect()
  }

  get sourceSize () {
    return {
      width: this.sourceTarget.getAttribute('width') || this.sourceTarget.offsetWidth,
      height: this.sourceTarget.getAttribute('height') || this.sourceTarget.offsetHeight,
    }
  }
}
