import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['transitionImage']

  interval = Number(this.data.get('interval')) || 2000
  delay = Number(this.data.get('delay')) || 500
  currentImageIndex = 0
  images = this.transitionImageTargets
  counter = this.images.length

  connect () {
    this.start()
  }

  nextImage () {
    this.fadeOutPrevious()
    this.currentImageIndex++

    if (this.currentImageIndex >= this.counter) {
      this.currentImageIndex = 0
    }

    setTimeout(() => this.fadeInNext(), this.delay)
  }

  start () {
    setInterval(() => {
      this.nextImage()
    }, this.interval)
  }

  fadeInNext () {
    this.images[this.currentImageIndex].classList.remove('invisible') // fade-in next
  }

  fadeOutPrevious () {
    this.images[this.currentImageIndex].classList.add('invisible') // fade-out previous
  }
}
