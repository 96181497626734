import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    elementSelector: String,
    requestUrl: String,
    useBubbling: { type: Boolean, default: false },
    ignoreClass: String,
  }

  connect() {
    this.requestMade = false

    const element = document.querySelector(this.elementSelectorValue)
    if (element) {
      element.addEventListener('click', this.requestOnce.bind(this), this.useBubblingValue)
    } else {
      console.error(`Element not found: ${this.elementSelectorValue}`)
    }
  }

  requestOnce(event) {
    if (this.ignoreClick(event.target)) {
      return
    }

    if (!this.requestMade) {
      this.requestMade = true

      try {
        const url = new URL(this.requestUrlValue)

        fetch(url)
          .then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok')
            }
          })
          .catch(error => {
            console.error('Request failed', error)
          })
      } catch (error) {
        console.error('Invalid URL', error)
      }
    }
  }

  ignoreClick(target) {
    if (this.ignoreClassValue) {
      const selector = `.${this.ignoreClassValue}`

      return target.closest(selector) !== null
    }
    return false
  }
}
