import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['days', 'hours', 'minutes', 'message']

  initialize () {
    this.startTime = new Date().getTime()
    this.updateCountdown()
  }

  connect () {
    this.internal = setInterval(() => this.updateCountdown(), 60000)
  }

  disconnect () {
    clearInterval(this.interval)
  }

  updateCountdown () {
    let deltaTime = this.scheduledDate.getTime() - new Date().getTime()

    if (this.isExpired()) {
      const redirectUrl = this.data.get('redirect-url')
      if (redirectUrl) {
        window.location.replace(redirectUrl)
      }
      deltaTime = new Date().getTime() - this.scheduledDate.getTime()
    }

    const minutes = 60 * 1000
    const hours = 60 * minutes
    const days = 24 * hours

    if (this.hasDaysTarget) {
      this.days = Math.floor(deltaTime / days)
    }

    if (this.hasHoursTarget) {
      this.hours = Math.floor((deltaTime % days) / hours)
    }

    if (this.hasMinutesTarget) {
      this.minutes = Math.ceil((deltaTime % hours) / minutes)
    }
  }

  isExpired () {
    return this.scheduledDate < new Date().getTime()
  }

  get scheduledDate () {
    if (this.data.get('timeLeft')) {
      return new Date(this.startTime + 60000 * Number(this.data.get('timeLeft')))
    } else {
      const [year, month, day] = this.data.get('scheduled-on').split('-')
      const [hours, minutes] = this.data.get('starts-at').split(':')

      return new Date(year, (month - 1), day, hours, minutes)
    }
  }

  set days (value) {
    this.daysTarget.textContent = value
  }

  set hours (value) {
    if (value < 10) value = [0, value].join('')
    this.hoursTarget.textContent = value
  }

  set minutes (value) {
    if (value < 10) value = [0, value].join('')
    this.minutesTarget.textContent = value
  }
}
