import Text from './text'

const Dom = {

  noscroll (status, element = document.body) {
    element.classList.toggle('noscroll', status)
  },

  show (element, animated) {
    if (animated) {
      element.style[animated.attribute] = animated.value
    } else if (element.classList.contains('hidden')) {
      element.classList.remove('hidden')
    } else {
      element.style.display = element.dataset.domDisplay || 'block'
    }
  },

  hide (element, animated) {
    if (animated) {
      element.style[animated.attribute] = animated.value
    } else if (element.style.display !== 'none') {
      element.dataset.domDisplay = element.style.display
      element.style.display = 'none'
    }
  },

  activate (element, force) {
    if (force === undefined) {
      element.classList.toggle('active')
    } else if (force) {
      element.classList.add('active')
    } else {
      element.classList.remove('active')
    }
  },

  set (element, value, options = {}) {
    const attribute = options.attribute || 'value'
    const event = options.event || 'changed'
    const format = element.dataset.valueFormat
    const suffix = element.dataset.valueSuffix

    const tag = element.tagName
    switch (tag) {
      case 'DIV':
        break
      case 'P':
      case 'LABEL':
      case 'SPAN':
        element.textContent = Text.translate(value, format, suffix)
        break
      case 'INPUT':
        element.value = value
        break
      default:
        console.log(`I don't know how to set values in ${tag}.`)
    }

    element.setAttribute(`data-${attribute}`, value.toString().replace(/,/, '.'))
    Dom.fire(element, event)
  },

  get (element, options = {}) {
    const attribute = options.attribute || 'value'
    const tag = element.tagName
    const formatter = element.dataset.formatter
    const value = element.value

    switch (tag) {
      case 'DIV':
      case 'P':
      case 'LABEL':
      case 'SPAN':
        return element.getAttribute(`data-${attribute}`)
      case 'INPUT':
      case 'TEXTAREA':
        return Text.unformat(value, formatter)
      default:
        console.log(`I don't know how to get values from ${tag}.`)
    }
  },

  fire (obj, name, data, options = {}) {
    const { bubbles, cancelable } = options

    const event = new CustomEvent(name, {
      bubbles: bubbles || false, // Under no circunstance change this. It will cause infinite loops.
      cancelable: cancelable || true,
      detail: data
    })
    obj.dispatchEvent(event)
    return !event.defaultPrevented
  }
}

window.Dom = Dom // Bobross: Figure out a better way to expose this

export default Dom
