import { Controller } from '@hotwired/stimulus'
import Parser from '../../lib/parser'
export default class extends Controller {
  static values = {
    eventId: String,
    scheduledOn: String,
    toBeDefined: Boolean,
    placeholderScheduledOn: Boolean,
    scheduled: Boolean,
    unsure: Boolean,
    status: String,
    completedStatus: String
  }

  connect () {
    this.update()
  }

  update () {
    const now = new Date()

    const day = now.getDate().toString().padStart(2, '0')
    const month = (now.getMonth() + 1).toString().padStart(2, '0')
    const year = now.getFullYear()

    const brasiliaTime = Parser.date(`${day}/${month}/${year}`)
    brasiliaTime.setHours(brasiliaTime.getHours() + 3)
    const scheduledOn = new Date(this.scheduledOnValue)

    if (this.toBeDefinedValue || this.placeholderScheduledOnValue) {
      this.element.innerText = 'A definir'
    } else if (!this.scheduledValue && !this.unsureValue) {
      this.element.innerText = this.statusValue
    } else if (scheduledOn.getTime() === brasiliaTime.getTime()) {
      this.element.innerText = 'É Hoje!'
    } else if (scheduledOn > brasiliaTime) {
      const MILLISECONDS_PER_DAY = 60 * 60 * 24 * 1000
      const differenceInDays = Math.ceil((scheduledOn - brasiliaTime) / MILLISECONDS_PER_DAY)
      document.getElementById(`${this.eventIdValue}`).innerText = `Falt${differenceInDays === 1 ? 'a' : 'am'}`
      this.element.innerText = `${differenceInDays} dia${differenceInDays === 1 ? '' : 's'}`
    } else {
      this.element.innerText = this.completedStatusValue
    }
  }
}
