import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    setTimeout(() => this.render(), this.delay)
  }

  render () {
    const xhr = new XMLHttpRequest()
    xhr.open('GET', `${this.url}${this.queryString()}`, true)
    xhr.overrideMimeType('X-Requested-With', 'XMLHttpRequest')
    xhr.setRequestHeader('Accept', 'text/html')
    xhr.onload = () => { this.element.outerHTML = xhr.responseText }
    xhr.send()
  }

  queryString () {
    const { width, height } = this.boundaries

    let params = ''
    if (!!width && width > 0 && this.forwardWidth) params += `&width=${width}`
    if (!!height && height > 0 && this.forwardHeight) params += `&height=${height}`

    return params
  }

  get boundaries () {
    return this.forwardWidth || this.forwardHeight ? this.element.getBoundingClientRect() : {}
  }

  get forwardBoundaries () {
    return this.data.get('forwardBoundaries') === 'true'
  }

  get forwardWidth () {
    return this.data.get('forwardWidth') === 'true' || this.forwardBoundaries
  }

  get forwardHeight () {
    return this.data.get('forwardHeight') === 'true' || this.forwardBoundaries
  }

  get url () {
    return this.data.get('url')
  }

  get delay () {
    return Number(this.data.get('delay') || 10)
  }
}
