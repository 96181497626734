import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    this.bootstrap().then(Trix => {
      Trix.start()
    })
  }

  // Only load Trix when necessary
  async bootstrap () {
    const { default: Trix } = await import(/* webpackChunkName: "trix" */ '../../chunks/trix')
    return Trix
  }
}
