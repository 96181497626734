import { Controller } from '@hotwired/stimulus'

import Glider from 'glider-js'

export default class extends Controller {
  static targets = ['items', 'prevItem', 'nextItem', 'dots']

  connect () {
    // eslint-disable-next-line no-new
    new Glider(this.itemsTarget, this.loadOptions())
  }

  loadOptions () {
    const options = {}
    this.loadDataOptions(options)
    this.loadElementOptions(options)
    return options
  }

  loadDataOptions (options) {
    this.loadDataOption(options, 'slidesToShow', 1, Number)
    this.loadDataOption(options, 'draggable', true, Boolean)
    this.loadDataOption(options, 'itemWidth', undefined, Number)
    this.loadDataOption(options, 'exactWidth', false, Boolean)
    this.loadDataOption(options, 'slidesToScroll', 1, Number)
  }

  loadDataOption (options, key, base, castCallback) {
    const val = this.data.get(key)
    options[key] = castCallback(val) || base
  }

  loadElementOptions (options) {
    options.arrows = {}
    if (this.hasDotsTarget) options.dots = this.dotsTarget
    if (this.hasPrevItemTarget) options.arrows.prev = this.prevItemTarget
    if (this.hasNextItemTarget) options.arrows.next = this.nextItemTarget
  }
}
