import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    this.initWidgets()
  }

  initWidgets () {
    this.element.dataset.controller += ' ajax form util'
  }
}
