import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['message', 'phone']
  static values = { url: String }

  open () {
    if (this.hasPhoneTarget) {
      window.open(`https://wa.me/${this.phoneTarget.value}?text=${encodeURIComponent(this.messageTarget.value)}`, '_blank')
    } else {
      window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(this.messageTarget.value)}`, '_blank')
    }
  }
}
