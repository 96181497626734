import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  openExternalApp (e) {
    const address = this.data.get('address')

    if (this.getMobileOperatingSystem() === 'iOS') {
      window.open('comgooglemaps://?daddr=' + address)
    } else {
      window.open('https://www.google.com/maps/search/?api=1&query=' + address)
    }
  }

  getMobileOperatingSystem () {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return 'Windows Phone'
    }

    if (/android/i.test(userAgent)) {
      return 'Android'
    }

    // iOS detection from: http://stackoverflow.com/a/903w9885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return 'iOS'
    }

    return 'unknown'
  }
}
