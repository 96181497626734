import { Controller } from '@hotwired/stimulus'
import Resizer from '../../lib/resizer'

export default class extends Controller {
  connect () {
    document.fonts ? this.resizeWhenFontsAreReady() : this.resize()
  }

  resizeWhenFontsAreReady () {
    document.fonts.ready.then(() => {
      this.resize()
    })
  }

  resize () {
    Resizer.allFonts(10)
  }
}
