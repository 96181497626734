import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['photo']

  initialize () { }

  open (event) {
    this.index = parseInt(event.target.closest('details').dataset.index)
    this.showButtons(event, this.index)
  }

  next (event) {
    this.index = parseInt(event.target.closest('details').dataset.index)
    this.closePhoto(this.index)
    this.index++
    this.togglePhoto(this.index)
  }

  previous (event) {
    this.index = parseInt(event.target.closest('details').dataset.index)
    this.closePhoto(this.index)
    this.index--
    this.togglePhoto(this.index)
  }

  close (event) {
    event.target.closest('details').removeAttribute('open')
  }

  closePhoto (index) {
    const item = document.querySelector(`[data-index='${index}']`)
    item.removeAttribute('open')
  }

  togglePhoto (index) {
    const item = document.querySelector(`[data-index='${index}']`)
    item.setAttribute('open', '')
  }

  showButtons (event, index) {
    this.hasPrevious ? event.target.querySelector('.previous-button').classList.remove('hidden') : false
    this.hasNext ? event.target.querySelector('.next-button').classList.remove('hidden') : false
  }

  stopPropagation (event) {
    event.stopPropagation()
  }

  get index () {
    return parseInt(this.data.get('index'))
  }

  set index (value) {
    this.data.set('index', value)
  }

  get hasNext () {
    return this.index < this.photoTargets.length
  }

  get hasPrevious () {
    return this.index > 1
  }
}
