import { Controller } from '@hotwired/stimulus'
import Util from '../../lib/util'

export default class extends Controller {
  static targets = ['content', 'slide', 'counter', 'marker']

  /* ==== Life Cycle ==== */
  initialize () {
    this.paginate()
    this.index = 0
  }

  /* ==== Initialization ==== */
  paginate () {
    this.contentTarget.classList.add('flx', 'ovr')
    const slidesPerPage = this.slidesPerPage

    this.slideTargets.forEach((element) => {
      element.classList.add('flx--fxd')
      element.style.flexBasis = `${100 / slidesPerPage}%`
    })

    this.addPageMarkers()
  }

  slide () {

  }

  /* ==== Actions ==== */
  previous () {
    if (this.index > 0) this.index--
  }

  next () {
    if (this.index < this.pages - 1) this.index++
  }

  /* ==== Business Logic === */
  showCurrentSlides () {
    const startSlide = this.index * this.slidesPerPage
    const endSlide = startSlide + this.slidesPerPage

    this.slideTargets.forEach((el, i) => {
      el.classList.toggle('hidden', i < startSlide || i >= endSlide)
    })

    this.markCurrentPage(this.index)
  }

  addPageMarkers () {
    if (this.pages < 2 || !this.hasCounterTarget) return

    for (let i = 0; i < this.pages; i++) {
      const marker = document.createElement('span')
      marker.className = 'slideshow__marker'
      marker.setAttribute('data-target', 'slideshow.marker')
      marker.setAttribute('data-slideshow-marker-index', i)

      this.counterTarget.append(marker)
    }
  }

  markCurrentPage () {
    this.markerTargets.forEach((marker, i) => { marker.classList.toggle('slideshow__marker--active', i === this.index) })
  }

  /* ==== Getters and Setters ==== */
  set index (value) {
    this.data.set('index', value)
    this.showCurrentSlides(value)
  }

  get index () {
    return Number(this.data.get('index'))
  }

  get pages () {
    return Math.ceil(this.slideTargets.length / this.slidesPerPage)
  }

  get slidesPerPage () {
    const slidesPerPage = this.data.get('slidesPerPage')
    if (!slidesPerPage) return 1

    const platforms = slidesPerPage.split('|').map((i) => parseInt(i, 10))
    return Util.isMobile() ? platforms[0] : platforms[1]
  }
}
