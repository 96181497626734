export default class Draggable {
  constructor (element, callback) {
    this.element = element
    this.callback = callback

    // Prepare the handlers so they use the current context and can still be removed later
    this.handlers = {
      down: this.down.bind(this),
      move: this.move.bind(this),
      up: this.up.bind(this)
    }

    element.addEventListener('mousedown', this.handlers.down)
    element.addEventListener('touchstart', this.handlers.down)
  }

  down (e) {
    if (this.isResizeEvent(e)) return

    // Cache the offset parent rectangle for better performance
    // Don't do this in the constructor since the parent might not be at its final size yet
    this.boundaries = this.element.offsetParent.getBoundingClientRect()

    // Remember the starting mouse position
    this.x = e.clientX || e.touches[0].clientX
    this.y = e.clientY || e.touches[0].clientY

    // Indicate you will start requesting animation frames
    this.needRAF = true

    document.addEventListener('mousemove', this.handlers.move)
    document.addEventListener('touchmove', this.handlers.move)
    document.addEventListener('mouseup', this.handlers.up)
    document.addEventListener('touchend', this.handlers.up)
  }

  move (e) {
    e.preventDefault()

    // This is an expensive operation, only allow it to run once per animation frame
    if (!this.needRAF) return

    this.needRAF = false
    requestAnimationFrame(() => {
      // Get the element's current position and size
      const rectangle = this.element.getBoundingClientRect()

      // Get the mouse/touch current position
      const x = e.clientX || e.touches[0].clientX
      const y = e.clientY || e.touches[0].clientY

      // Calculate how far the element can be moved
      const dx = this.enforceHorizontalBoundary(rectangle, x - this.x)
      const dy = this.enforceVerticalBoundary(rectangle, y - this.y)

      // Calculate mouse/touch position inside the offset element
      const pageX = this.calculateRelativePosition(x, this.boundaries.x, this.boundaries.width)
      const pageY = this.calculateRelativePosition(y, this.boundaries.y, this.boundaries.height)

      // Issue the callback
      this.callback({ dx, dy, pageX, pageY, rectangle, boundaries: this.boundaries, target: this.element })

      // Reassign the position of mouse/touch
      this.x = x
      this.y = y

      this.needRAF = true
    })
  }

  up () {
    // Indicate you will stop requesting animation frames
    this.needRAF = false

    // Remove the handlers of `mousemove` and `mouseup`
    document.removeEventListener('mousemove', this.handlers.move)
    document.removeEventListener('touchmove', this.handlers.move)
    document.removeEventListener('mouseup', this.handlers.up)
    document.removeEventListener('touchend', this.handlers.up)
  }

  enforceHorizontalBoundary (rectangle, dx) {
    if (dx < 0) {
      const space = rectangle.x - this.boundaries.x
      return Math.abs(dx) > space ? -space : dx
    } else {
      const space = (this.boundaries.x + this.boundaries.width) - (rectangle.x + rectangle.width)
      return dx > space ? space : dx
    }
  }

  enforceVerticalBoundary (rectangle, dy) {
    if (dy < 0) {
      const space = rectangle.y - this.boundaries.y
      return Math.abs(dy) > space ? -space : dy
    } else {
      const space = (this.boundaries.y + this.boundaries.height) - (rectangle.y + rectangle.height)
      return dy > space ? space : dy
    }
  }

  calculateRelativePosition (position, start, length) {
    if (position < start) {
      return 0
    } else if (position > start + length) {
      return length
    } else {
      return position - start
    }
  }

  isResizeEvent (e) { // TODO: This is temporary, we need a better solution
    if (e.target.classList.contains('setout__fragment')) {
      const rect = e.target.getBoundingClientRect()
      const x = e.clientX - rect.right
      const y = e.clientY - rect.bottom
      return x > -15 && y > -15
    }
  }
}
