import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static classes = ['add', 'remove']
  static values = { delay: Number }

  connect () {
    this.animateByAdding()
    this.animateByRemoving()
  }

  animateByAdding () {
    if (!this.hasAddClass) return

    setTimeout(() => {
      this.element.classList.add(this.addClass)
    }, this.delay)
  }

  animateByRemoving () {
    if (!this.hasRemoveClass) return

    setTimeout(() => {
      this.element.classList.remove(this.removeClass)
    }, this.delay)
  }

  get delay () {
    return this.hasDelayValue ? this.delayValue : 100
  }
}
