import Binder from '../lib/binder'
import Formatter from '../lib/formatter'

const Invitations = {
  start () {
    Binder.raw('#invitation_person_name', 'child-name')
    Binder.raw('#invitation_person_name', 'person-name')
    Binder.raw('#invitation_location', 'event-location')
    Binder.raw('#invitation_neighborhood', 'address-neighborhood')
    Binder.raw('#invitation_city', 'address-city')
    Binder.raw('#invitation_secondary_person_name', 'secondary-person-name')
    Binder.raw('#invitation_secondary_location', 'secondary-event-location')
    Binder.raw('#invitation_secondary_neighborhood', 'secondary-address-neighborhood')
    Binder.raw('#invitation_secondary_city', 'secondary-address-city')

    Binder.raw('#invitation_starts_at', 'event-starts_at')
    Binder.raw('#invitation_public_url', 'event-public-url')
    Binder.raw('#invitation_rsvp_closes_on', 'event-rsvp-date')
    Binder.raw('#invitation_gift_suggestion', 'event-gift-suggestion')

    Binder.join(['#invitation_starts_at_hour', '#invitation_starts_at_minutes'], 'invitation_starts_at', ':')
    Binder.join(['#invitation_person_name', '#invitation_secondary_person_name'], 'couple-names', ' & ')
    Binder.join(['#invitation_person_name', '#invitation_secondary_person_name'], 'couple-names-with-e', ' e ')
    Binder.join(['#invitation_street', '#invitation_number', '#invitation_complement'], 'address-building', ', ')
    Binder.join(['#invitation_street', '#invitation_number', '#invitation_complement', '#invitation_neighborhood'], 'address-building-neighborhood', ', ')
    Binder.join(['#invitation_city', '#invitation_state'], 'address-city-state', ' - ')
    Binder.join(['#invitation_neighborhood', '#invitation_city'], 'address-neighborhood-city', ' - ')
    Binder.join(['#invitation_neighborhood', '#invitation_city', '#invitation_state'], 'address-neighborhood-city-state', ' - ')
    Binder.join(['#invitation_secondary_street', '#invitation_secondary_number', '#invitation_secondary_complement'], 'secondary-address-building', ', ')
    Binder.join(['#invitation_secondary_street', '#invitation_secondary_number', '#invitation_secondary_complement', '#invitation_secondary_neighborhood'], 'secondary-address-building-neighborhood', ', ')
    Binder.join(['#invitation_secondary_city', '#invitation_secondary_state'], 'secondary-address-city-state', ' - ')
    Binder.join(['#invitation_secondary_neighborhood', '#invitation_secondary_city'], 'secondary-address-neighborhood-city', ' - ')
    Binder.join(['#invitation_secondary_neighborhood', '#invitation_secondary_city', '#invitation_secondary_state'], 'secondary-address-neighborhood-city-state', ' - ')
    Binder.join(['#invitation_scheduled_on', '#invitation_city'], 'event-date-city', ' | ')

    Binder.formatted('#invitation_classification', 'event-title-without-article', Formatter.classification)
    Binder.formatted('#invitation_person_gender', 'event-title-customized', Formatter.gender_connective)
    Binder.formatted('#invitation_person_gender', 'event-gender-connective', Formatter.gender_connective)
    Binder.formatted('#invitation_scheduled_on', 'event-day', Formatter.day)
    Binder.formatted('#invitation_scheduled_on', 'event-month', Formatter.month)
    Binder.formatted('#invitation_scheduled_on', 'event-year', Formatter.year)
    Binder.formatted('#invitation_scheduled_on', 'event-short-year', Formatter.short_year)
    Binder.formatted('#invitation_scheduled_on', 'event-month-number', Formatter.month_number)
    Binder.formatted('#invitation_scheduled_on', 'event-short-month', Formatter.short_month)
    Binder.formatted('#invitation_scheduled_on', 'event-weekday', Formatter.weekday)
    Binder.formatted('#invitation_scheduled_on', 'event-short-weekday', Formatter.short_weekday)
    Binder.formatted('#invitation_scheduled_on', 'event-date', Formatter.date)
    Binder.formatted('#invitation_scheduled_on', 'event-date-dot', Formatter.date_dot)
    Binder.formatted('#invitation_scheduled_on', 'event-date-period', Formatter.date_period)
    Binder.formatted('#invitation_person_age', 'child-age', Formatter.age)
    Binder.formatted('#invitation_person_age', 'child-age-diminutive', Formatter.age_diminutive)
    Binder.formatted('#invitation_person_age', 'child-age-number', Formatter.age_number)
    Binder.formatted('#invitation_person_age', 'child-age-number-secondary', Formatter.age_number)
    Binder.formatted('#invitation_person_age', 'child-age-ordinal-number', Formatter.ordinal_number)
    Binder.formatted('#invitation_person_age', 'child-age-text', Formatter.age_text)
    Binder.formatted('#invitation_person_age', 'child-short-age', Formatter.short_age)
    Binder.formatted('#invitation_person_age', 'person-age-celebrate', Formatter.celebrate_prefix)
    Binder.formatted('#invitation_starts_at', 'event-hour-connective', Formatter.hour_connective)
    Binder.formatted('#invitation_starts_at', 'event-hour-with-connective', Formatter.simple_hour_connective)
    Binder.formatted('#invitation_starts_at', 'event-hour-long', Formatter.hour)
    Binder.formatted('#invitation_starts_at', 'event-hour-text', Formatter.hour_text)
    Binder.formatted('#invitation_starts_at', 'event-hour_with_prefix', Formatter.hour_with_prefix)
    Binder.formatted('#invitation_starts_at', 'event-hour-h', Formatter.hour_h)
    Binder.formatted('#invitation_starts_at', 'event-short-hour-h', Formatter.short_hour_h)
    Binder.formatted('#invitation_person_name', 'child-name-initial', Formatter.first_letter)
    Binder.formatted('#invitation_person_name', 'person-name-initial', Formatter.first_letter)
    Binder.formatted('#invitation_secondary_person_name', 'secondary-person-name-initial', Formatter.first_letter)
    Binder.formatted('#invitation_gift_suggestion', 'gift-suggestion-with-title', Formatter.gift_suggestion_with_title)
    Binder.formatted('#invitation_secondary_person_name', 'secondary-person-name-with-prefix', Formatter.name_with_prefix)
    Binder.gendered('#invitation_person_gender', 'event-gendered-title')
    Binder.pluralizable('#invitation_person_age')
    Binder.optionPicker('#invitation_classification', 'event-classification')
    Binder.conditional('#invitation_gift_suggestion', 'event-gift-suggestion-message')
    Binder.conditional_target('#invitation_gift_suggestion', 'event-gift-suggestion-default-message', 'event-gift-suggestion-message')

    Binder.custom([
      { selector: '#invitation_starts_at_hour' },
      { selector: '#invitation_starts_at_minutes' }
    ], 'invitation_starts_at', ':')

    Binder.custom([
      { selector: '#invitation_person_name' },
      { selector: '#invitation_secondary_person_name' }
    ], 'invitation_public_url', ' e ', Formatter.slug)

    Binder.custom([
      { selector: '#invitation_classification', callback: Formatter.classification },
      { selector: '#invitation_person_gender', callback: Formatter.gender_connective }
    ], 'event-title', ' ')

    Binder.custom([
      { selector: '#invitation_classification', callback: Formatter.classification },
      { selector: '#invitation_person_gender', callback: Formatter.gender_connective },
      { selector: '#invitation_person_name' }
    ], 'event-title-with-connector', ' ')

    Binder.custom([
      { selector: '#invitation_classification', callback: Formatter.classification },
      { selector: '#invitation_person_gender', callback: Formatter.gender_connective },
      { selector: '#invitation_person_name' }
    ], 'event-title-welcoming', ' ', Formatter.event_title_welcoming)

    Binder.custom([
      { selector: '#invitation_person_name' },
      { selector: '#invitation_person_age', callback: Formatter.numbers }
    ], 'child-name-with-age', ' faz ', Formatter.name_age_connector_pluralizable)

    Binder.custom([
      { selector: '#invitation_person_gender', callback: Formatter.gender_connective },
      { selector: '#invitation_person_name' }
    ], 'person-name-with-gender-connective', ' ')

    Binder.custom([
      { selector: '#invitation_person_name' },
      { selector: '#invitation_person_age', callback: Formatter.age }
    ], 'person-name-and-age', ' faz ', Formatter.name_age_connector_pluralizable)

    Binder.custom([
      { selector: '#invitation_person_age', callback: Formatter.age },
      { selector: '#invitation_person_gender', callback: Formatter.gender_connective },
      { selector: '#invitation_person_name' }
    ], 'person-name-and-age-with-gender', [' ', ' '])

    Binder.custom([
      { selector: '#invitation_person_name', callback: Formatter.short_birthday_prefix },
      { selector: '#invitation_person_gender', callback: Formatter.gender_connective },
      { selector: '#invitation_person_name' },
      { selector: '#invitation_person_age', callback: Formatter.age }
    ], 'person-name-and-age-with-short-birthday-prefix', [' ', ' ', ', '])

    Binder.custom([
      { selector: '#invitation_person_age', callback: Formatter.age_with_party_prefix },
      { selector: '#invitation_person_gender', callback: Formatter.gender_connective },
      { selector: '#invitation_person_name' }
    ], 'person-age-and-name-with-party-prefix', [' ', ' '])

    Binder.custom([
      { selector: '#invitation_person_age', callback: Formatter.age_with_invite_prefix },
      { selector: '#invitation_person_name' }
    ], 'person-age-and-name-with-invite-prefix', ' de ')

    Binder.custom([
      { selector: '#invitation_person_age', callback: Formatter.age },
      { selector: '#invitation_person_name' }
    ], 'person-age-and-name', ' de ')

    Binder.custom([
      { selector: '#invitation_scheduled_on', callback: Formatter.weekday },
      { selector: '#invitation_starts_at' }
    ], 'event-weekday-hour', ' | ')

    Binder.custom([
      { selector: '#invitation_scheduled_on', callback: Formatter.weekday },
      { selector: '#invitation_starts_at' }
    ], 'event-weekday-hour-with-connective', ' às ')

    Binder.custom([
      { selector: '#invitation_scheduled_on', callback: Formatter.weekday },
      { selector: '#invitation_starts_at', callback: Formatter.hour_text }
    ], 'event-weekday-hour-readable', ', ')

    Binder.custom([
      { selector: '#invitation_scheduled_on', callback: Formatter.short_weekday },
      { selector: '#invitation_starts_at' }
    ], 'event-short-weekday-hour-double-colon', ' :: ')

    Binder.custom([
      { selector: '#invitation_scheduled_on', callback: Formatter.short_weekday },
      { selector: '#invitation_starts_at' }
    ], 'event-short-weekday-hour', ' | ')

    Binder.custom([
      { selector: '#invitation_scheduled_on', callback: Formatter.short_weekday },
      { selector: '#invitation_starts_at', callback: Formatter.hour_h }
    ], 'event-short-weekday-hour-without-connective', ' ')

    Binder.custom([
      { selector: '#invitation_scheduled_on', callback: Formatter.short_weekday },
      { selector: '#invitation_starts_at' }
    ], 'event-short-weekday-hour-with-connective', ' às ')

    Binder.custom([
      { selector: '#invitation_scheduled_on', callback: Formatter.short_weekday },
      { selector: '#invitation_starts_at', callback: Formatter.hour_h }
    ], 'event-short-weekday-hour-with-colon-and-connective', ', às ')

    Binder.custom([
      { selector: '#invitation_scheduled_on', callback: Formatter.very_short_weekday },
      { selector: '#invitation_starts_at' }
    ], 'event-very-short-weekday-hour', ' | ')

    Binder.custom([
      { selector: '#invitation_scheduled_on', callback: Formatter.weekday },
      { selector: '#invitation_starts_at', callback: Formatter.hour }
    ], 'event-weekday-hour-long', ' | ')

    Binder.custom([
      { selector: '#invitation_scheduled_on', callback: Formatter.day },
      { selector: '#invitation_scheduled_on', callback: Formatter.month }
    ], 'event-day-month', ' de ')

    Binder.custom([
      { selector: '#invitation_rsvp_closes_on', callback: Formatter.day },
      { selector: '#invitation_rsvp_closes_on', callback: Formatter.month }
    ], 'event-rsvp', ' de ')

    Binder.custom([
      { selector: '#invitation_rsvp_closes_on', callback: Formatter.day },
      { selector: '#invitation_rsvp_closes_on', callback: Formatter.month },
      { selector: '#invitation_rsvp_closes_on', callback: Formatter.year }
    ], 'event-rsvp-full', ' de ')

    Binder.custom([
      { selector: '#invitation_scheduled_on', callback: Formatter.day },
      { selector: '#invitation_scheduled_on', callback: Formatter.month },
      { selector: '#invitation_scheduled_on', callback: Formatter.year }
    ], 'event-day-month-year-with-connective', ' de ')

    Binder.custom([
      { selector: '#invitation_scheduled_on', callback: Formatter.day },
      { selector: '#invitation_scheduled_on', callback: Formatter.month }
    ], 'event-day-month-no-connector', ' ')

    Binder.custom([
      { selector: '#invitation_scheduled_on', callback: Formatter.day },
      { selector: '#invitation_scheduled_on', callback: Formatter.short_month }
    ], 'event-day-short-month', ' ')

    Binder.custom([
      { selector: '#invitation_scheduled_on', callback: Formatter.weekday },
      { selector: '#invitation_scheduled_on', callback: Formatter.day },
      { selector: '#invitation_scheduled_on', callback: Formatter.month }
    ], 'event-weekday-day-month', [', ', ' de '])

    Binder.custom([
      { selector: '#invitation_scheduled_on', callback: Formatter.short_weekday },
      { selector: '#invitation_scheduled_on', callback: Formatter.day },
      { selector: '#invitation_scheduled_on', callback: Formatter.month }
    ], 'event-short-weekday-day-month', [', ', ' de '])

    Binder.custom([
      { selector: '#invitation_scheduled_on', callback: Formatter.short_weekday },
      { selector: '#invitation_scheduled_on', callback: Formatter.day },
      { selector: '#invitation_scheduled_on', callback: Formatter.month },
      { selector: '#invitation_starts_at' }
    ], 'event-short-weekday-day-month-hour', [', ', ' de ', ' às '])

    Binder.custom([
      { selector: '#invitation_scheduled_on', callback: Formatter.short_weekday },
      { selector: '#invitation_scheduled_on', callback: Formatter.day },
      { selector: '#invitation_scheduled_on', callback: Formatter.month },
      { selector: '#invitation_scheduled_on', callback: Formatter.year },
      { selector: '#invitation_starts_at' }
    ], 'event-short-weekday-day-month-year-hour', [', ', ' de ', ' de ', ' às '])

    Binder.custom([
      { selector: '#invitation_scheduled_on', callback: Formatter.day },
      { selector: '#invitation_scheduled_on', callback: Formatter.month },
      { selector: '#invitation_scheduled_on', callback: Formatter.short_weekday },
      { selector: '#invitation_starts_at' }
    ], 'event-day-month-short-weekday-hour-with-connective', [' de ', ' - ', ' às '])

    Binder.custom([
      { selector: '#invitation_scheduled_on', callback: Formatter.short_weekday },
      { selector: '#invitation_scheduled_on', callback: Formatter.day },
      { selector: '#invitation_scheduled_on', callback: Formatter.short_month },
      { selector: '#invitation_starts_at' }
    ], 'event-short-weekday-day-short-month-hour', [', ', ' de ', ' às '])

    Binder.custom([
      { selector: '#invitation_scheduled_on', callback: Formatter.day },
      { selector: '#invitation_scheduled_on', callback: Formatter.short_month },
      { selector: '#invitation_scheduled_on', callback: Formatter.short_weekday },
      { selector: '#invitation_starts_at' }
    ], 'event-day-short-month-weekday-hour-pipe', [' ', ' | ', ' | '])

    Binder.custom([
      { selector: '#invitation_scheduled_on', callback: Formatter.day },
      { selector: '#invitation_scheduled_on', callback: Formatter.short_month },
      { selector: '#invitation_scheduled_on', callback: Formatter.short_weekday },
      { selector: '#invitation_starts_at' }
    ], 'event-day-short-month-weekday-hour-break-line', [' ', '<br>', '<br>'])

    Binder.custom([
      { selector: '#invitation_scheduled_on', callback: Formatter.day },
      { selector: '#invitation_scheduled_on', callback: Formatter.short_month }
    ], 'event-day-short-month', ' ')

    Binder.custom([
      { selector: '#invitation_street' },
      { selector: '#invitation_number' },
      { selector: '#invitation_complement' },
      { selector: '#invitation_neighborhood' },
      { selector: '#invitation_city' },
      { selector: '#invitation_state' }
    ], 'address-complete', [', ', ', ', ', ', '. ', ' - '])

    Binder.custom([
      { selector: '#invitation_street' },
      { selector: '#invitation_number' },
      { selector: '#invitation_complement' },
      { selector: '#invitation_neighborhood' }
    ], 'address-building-dash-neighborhood', [', ', ' - ', ', '])

    Binder.custom([
      { selector: '#invitation_city' },
      { selector: '#invitation_state' },
      { selector: '#invitation_zipcode' }

    ], 'address-city-state-with-zipcode', [' - ', ', '])

    Binder.custom([
      { selector: '#invitation_street' },
      { selector: '#invitation_number' },
      { selector: '#invitation_complement' },
      { selector: '#invitation_neighborhood' },
      { selector: '#invitation_city' }
    ], 'address-without-state', [', ', ', ', ', ', '. '])

    Binder.custom([
      { selector: '#invitation_street' },
      { selector: '#invitation_number' },
      { selector: '#invitation_city' }
    ], 'address-street-and-city-break-line', [', ', '<br>'])

    Binder.custom([
      { selector: '#invitation_secondary_street' },
      { selector: '#invitation_secondary_number' },
      { selector: '#invitation_secondary_complement' },
      { selector: '#invitation_secondary_neighborhood' },
      { selector: '#invitation_secondary_city' },
      { selector: '#invitation_state' }
    ], 'secondary-address-complete', [', ', ', ', ', ', '. ', ' - '])

    Binder.custom([
      { selector: '#invitation_location', callback: Formatter.ceremony_prefix },
      { selector: '#invitation_street' },
      { selector: '#invitation_number' },
      { selector: '#invitation_complement' },
      { selector: '#invitation_neighborhood' },
      { selector: '#invitation_city' },
      { selector: '#invitation_state' }
    ], 'event-ceremony-address', ['. ', ', ', ', ', ', ', '. ', ' - '])

    Binder.custom([
      { selector: '#invitation_location' },
      { selector: '#invitation_street' },
      { selector: '#invitation_number' },
      { selector: '#invitation_complement' },
      { selector: '#invitation_neighborhood' },
      { selector: '#invitation_city' },
      { selector: '#invitation_state' }
    ], 'event-ceremony-address-without-title', ['. ', ', ', ', ', ', ', '. ', ' - '])

    Binder.custom([
      { selector: '#invitation_secondary_location', callback: Formatter.reception_prefix },
      { selector: '#invitation_secondary_street' },
      { selector: '#invitation_secondary_number' },
      { selector: '#invitation_secondary_complement' },
      { selector: '#invitation_secondary_neighborhood' },
      { selector: '#invitation_secondary_city' },
      { selector: '#invitation_state' }
    ], 'event-reception-address', ['. ', ', ', ', ', ', ', '. ', ' - '])

    Binder.custom([
      { selector: '#invitation_secondary_street' },
      { selector: '#invitation_secondary_number' },
      { selector: '#invitation_secondary_complement' },
      { selector: '#invitation_secondary_neighborhood' }
    ], 'secondary-address-building-dash-neighborhood', [', ', ' - ', ', '])

    Binder.custom([
      { selector: '#invitation_secondary_city' },
      { selector: '#invitation_secondary_state' },
      { selector: '#invitation_secondary_zipcode' }

    ], 'secondary-address-city-state-with-zipcode', [' - ', ', '])

    Binder.custom([
      { selector: '#invitation_person_name', callback: Formatter.first_letter },
      { selector: '#invitation_secondary_person_name', callback: Formatter.first_letter }
    ], 'couple-name-initials-with-ampersand', ' & ')

    Binder.custom([
      { selector: '#invitation_person_name', callback: Formatter.first_letter },
      { selector: '#invitation_secondary_person_name', callback: Formatter.first_letter }
    ], 'couple-name-initials', '')

    Binder.custom([
      { selector: '#invitation_rsvp_closes_on', callback: Formatter.rsvp_with_prefix },
      { selector: '#invitation_public_url' }
    ], 'event-rsvp-date-and-url', ' ')

    Binder.custom([
      { selector: '#invitation_scheduled_on', callback: Formatter.date_dot_default },
      { selector: 'invitation_city' }
    ], 'event-date-period-city', ' | ')

    Binder.placeholder('#invitation_rsvp_closes_on', '#invitation_public_url', 'event-rsvp')
    Binder.placeholder('#invitation_rsvp_closes_on', '#invitation_public_url', 'event-rsvp-date')
    Binder.placeholder('#invitation_rsvp_closes_on', '#invitation_public_url', 'event-rsvp-full')
    Binder.placeholder('#invitation_rsvp_closes_on', '#invitation_public_url', 'event-rsvp-date-and-url')
  }
}

export default Invitations
