import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['map', 'address', 'lat', 'lng', 'name', 'pac']

  disconnect () {
    if (this.observer) this.observer.disconnect(document.body)
  }

  init () {
    if (this.isInitialized) return

    const checkExist = setInterval(() => {
      if (typeof google === 'object') {
        clearInterval(checkExist)

        this.initMarkers()
        this.initAutoComplete()
        this.initMap()
        this.initObserver()

        this.isInitialized = true
      }
    })
  }

  initMarkers () {
    this.markers = []
  }

  initObserver () {
    this.observer = new MutationObserver((mutations, _) => {
      for (let i = 0; i < mutations.length; ++i) {
        for (let j = 0; j < mutations[i].addedNodes.length; ++j) {
          if (mutations[i].addedNodes[j].classList.contains('pac-container')) {
            this.pacTarget.appendChild((mutations[i].addedNodes[j]))
          }
        }
      }
    })

    this.observer.observe(document.body, { childList: true })
  }

  initAutoComplete () {
    this.autocomplete = new google.maps.places.Autocomplete(this.addressTarget, { types: ['geocode', 'establishment'] })
    this.autocomplete.setFields(['address_component', 'name', 'formatted_address', 'geometry'])
    this.autocomplete.addListener('place_changed', () => {
      const place = this.autocomplete.getPlace()
      if (!place || !place.formatted_address) return

      this.address = place.formatted_address
      this.latitude = place.geometry.location.lat()
      this.longitude = place.geometry.location.lng()
      this.updateMap()

      this.data.set('searched', true)
    })
  }

  initMap () {
    this.map = new google.maps.Map(this.mapTarget, {
      zoom: 3,
      center: { lat: -14.2, lng: -51.9 },
      mapTypeControl: false,
      panControl: false,
      zoomControl: false,
      streetViewControl: false
    })

    this.map.addListener('bounds_changed', () => {
      this.autocomplete.setBounds(this.map.getBounds())
    })

    if (this.hasCoordinates()) {
      this.updateMap()
    }
  }

  geocode () {
    new google.maps.Geocoder().geocode({ address: this.addressTarget.value }, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK && !!results[0].geometry) {
        this.latitude = results[0].geometry.location.lat()
        this.longitude = results[0].geometry.location.lng()
        this.updateMap()
      } else {
        this.geolocate()
      }
    })
  }

  geolocate () {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude
        this.longitude = position.coords.longitude
        this.updateMap()
      })
    }
  }

  updateMap () {
    this.markers.forEach(function (marker) {
      marker.setMap(null)
    })
    this.markers = []
    this.markers.push(new google.maps.Marker({ map: this.map, position: this.location }))

    this.map.setCenter(this.location)
    this.map.setZoom(15)
  }

  hasCoordinates () {
    return !!this.latitude && this.longitude
  }

  get location () {
    return {
      lat: this.latitude,
      lng: this.longitude
    }
  }

  set name (value) {
    if (this.hasNameTarget) { this.nameTarget.value = value }
  }

  get latitude () {
    return parseFloat(this.latTarget.value)
  }

  set latitude (value) {
    this.latTarget.value = value
  }

  get longitude () {
    return parseFloat(this.lngTarget.value)
  }

  set longitude (value) {
    this.lngTarget.value = value
  }

  get address () {
    return this.addressTarget
  }

  set address (value) {
    this.addressTarget.value = value
  }

  set isInitialized (value) {
    this.data.set('isInitialized', value)
  }
}
