import { Controller } from '@hotwired/stimulus'
import ClipboardJS from 'clipboard'

export default class extends Controller {
  static targets = ['button']
  static classes = ['copied']
  static values = { copied: String }

  connect () {
    this.clipboard = new ClipboardJS(this.element)
    this.clipboard.on('success', this.notify.bind(this))
  }

  disconnect () {
    this.clipboard.destroy()
  }

  notify (e) {
    const button = this.clipboardButton(e)
    const originalText = button.innerHTML

    button.innerHTML = this.copiedText
    if (this.hasCopiedClass) button.classList.add(this.copiedClass)

    setTimeout(() => {
      button.innerHTML = originalText
      if (this.hasCopiedClass) button.classList.remove(this.copiedClass)
    }, 3000)
  }

  clipboardButton (e) {
    if (this.hasButtonTarget) {
      return this.buttonTarget
    } else {
      return e.trigger || e.target
    }
  }

  get copiedText () {
    return this.hasCopiedValue ? this.copiedValue : 'Copiado!'
  }
}
