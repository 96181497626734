import { DirectUpload } from '../rails/activestorage'

export default class {
  constructor (input) {
    this.input = input

    this.submit = input.form.querySelector('.btn-submit')
    this.progress = this.submit.querySelector('.btn-submit-progress')
    this.loader = this.submit.querySelector('.btn-submit-loader')
  }

  upload () {
    return new Promise((resolve, reject) => {
      const file = this.input.files[0]
      const url = this.input.dataset.directUploadUrl

      if (!file) {
        resolve('Nothing to upload')
        return
      }

      this.submit.classList.add('btn-submit-uploading')
      this.directUpload(this.submit, this.input, file, url, resolve, reject, this)
    }).catch(() => {
      // Do nothing. Yes nothing, I just want Sentry to shut up.
    }).then(() => {
      // For some bullshit reason direct uploads disable all fields in the browser and
      // this breaks ajax controller. Reenable the stuff.
      this.input.form.elements.forEach(element => {
        element.disabled = false
      })
    })
  }

  directUpload (submit, input, file, url, resolve, reject, caller) {
    new DirectUpload(file, url, caller).create((error, blob) => {
      if (error) {
        submit.classList.remove('btn-submit-uploading')
        reject(error)
      } else {
        const hiddenField = document.createElement('input')
        hiddenField.setAttribute('type', 'hidden')
        hiddenField.setAttribute('value', blob.signed_id)
        hiddenField.name = input.name
        input.form.appendChild(hiddenField)
        resolve('Upload done')
      }
    })
  }

  directUploadWillStoreFileWithXHR (request) {
    request.upload.addEventListener('progress',
      event => this.directUploadDidProgress(event))
  }

  directUploadDidProgress (event) {
    this.progress.style.width = `${event.loaded / event.total * 100}%`
  }
}
