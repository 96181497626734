import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['page', 'previous', 'next', 'display', 'thumbnail', 'dots', 'slider']
  static classes = ['thumbnailActive']

  initialize () {
    this.showCurrentSlide()
    this.setCurrentThumbnail()
    this.showCurrentIndex()
    this.toggleButtons()
    this.buildDots()
    this.observeSlides()
  }

  next () {
    if (this.index < this.pageTargets.length - 1) this.index++
    this.toggleThumbnails()
    this.updateIndexChip(this.index)
  }

  previous () {
    if (this.index > 0) this.index--
    this.toggleThumbnails()
    this.updateIndexChip(this.index)
  }

  go (e) {
    this.index = e.currentTarget.dataset.slide
    this.toggleThumbnails()
    this.updateIndexChip(this.index)
  }

  showCurrentSlide () {
    if (this.showAllItems) return

    this.pageTargets.forEach((el, i) => {
      el.classList.toggle('hidden', this.index !== i)
    })
  }

  setCurrentThumbnail () {
    this.thumbnailTargets.forEach((thumbnail, index) => {
      thumbnail.classList.toggle(this.thumbnailActiveClass, index === this.index)
    })
  }

  toggleThumbnails () {
    if (this.index === this.thumbnailTargets.length - 1 || this.platform === 'mobile') return

    if (this.index >= this.lastThumbnailIndex) {
      this.navigationStart = true
      this.thumbnailTargets[this.firstThumbnailIndex].classList.toggle('hidden')
      this.firstThumbnailIndex++

      this.lastThumbnailIndex++
      this.thumbnailTargets[this.lastThumbnailIndex].classList.toggle('hidden')
    } else if (this.navigationStart && this.firstThumbnailIndex > 0) {
      this.firstThumbnailIndex--
      this.thumbnailTargets[this.firstThumbnailIndex].classList.toggle('hidden')

      this.thumbnailTargets[this.lastThumbnailIndex].classList.toggle('hidden')
      this.lastThumbnailIndex--
    }
  }

  toggleButtons () {
    if (this.hasPreviousTarget && this.hasNextTarget) {
      this.previousTarget.classList.toggle('hidden', this.index === 0)
      this.nextTarget.classList.toggle('hidden', this.index === this.pageTargets.length - 1)
    }
  }

  showCurrentIndex () {
    if (this.hasDisplayTarget) {
      this.displayTarget.innerHTML = this.index + 1
    }
  }

  buildDots () {
    if (!this.hasDotsTarget) return

    this.clearDots()
    this.setDots()
  }

  clearDots () {
    while (this.dotsTarget.children.length > 1) {
      this.lastItem = this.dotsTarget.children.item(this.dotsTarget.children.length - 1)
      this.lastItem.parentElement.removeChild(this.lastItem.parentElement.lastChild)
    }
  }

  setDots () {
    const firstDot = this.dotsTarget.children[0]
    for (let i = 0; i < (this.pageTargets.length - 1); i++) {
      const newDot = firstDot.cloneNode(true)
      this.dotsTarget.appendChild(newDot)
    }
    this.setDotAsActive(0)
  }

  observeSlides () {
    this.pageTargets.forEach((page) => {
      let observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.setActiveSlide(entry.target)
          }
        })
      }, { threshold: .4 })
      observer.observe(page)
    })
  }

  setActiveSlide (page) {
    const slide = Number(page.dataset.slide)

    this.setDotAsActive(slide)
    this.updateIndexChip(slide)
  }

  setDotAsActive (index) {
    if (!this.hasDotsTarget) return

    const dots = this.dotsTarget.children

    dots.forEach(dot => dot.classList.add('bg-gray'))
    dots[index].classList.add('bg-primary')
    dots[index].classList.remove('bg-gray')
  }

  updateIndexChip (index) {
    let chip = document.querySelector('#chip-count > span')
    if (chip) {
      let active_index = index + 1
      chip.innerText = active_index + '/' + this.pageTargets.length
    }
  }

  get index () {
    return parseInt(this.data.get('index'))
  }

  set index (value) {
    this.data.set('index', value)
    this.showCurrentSlide()
    this.setCurrentThumbnail()
    this.showCurrentIndex()
    this.toggleButtons()
  }

  get firstThumbnailIndex () {
    return parseInt(this.data.get('first-thumbnail-index'))
  }

  set firstThumbnailIndex (value) {
    this.data.set('first-thumbnail-index', value)
  }

  get lastThumbnailIndex () {
    return parseInt(this.data.get('last-thumbnail-index'))
  }

  set lastThumbnailIndex (value) {
    this.data.set('last-thumbnail-index', value)
  }

  get navigationStart () {
    return Boolean(this.data.get('navigation-start'))
  }

  set navigationStart (value) {
    this.data.set('navigation-start', value)
  }

  get platform () {
    return this.data.get('platform')
  }

  get showAllItems () {
    return Boolean(this.data.get('show-all-items'))
  }
}
