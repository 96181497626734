import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['crumbs', 'past', 'current']
  static values = {
    id: String,
    index: Number,
    title: String
  }

  /* ==== LIFE CYCLE ==== */
  connect () {
    if (this.valid()) {
      this.pushState()
      this.displayState()
    }
  }

  /* ==== INTERNAL API */
  pushState () {
    const history = this.history

    // This step is already in the history, just ignore it.
    if (history[this.indexValue] && history[this.indexValue].url === this.state.url) return

    // If there are 4 steps in the current history, and we are setting step 3,
    // we must remove the existing steps 3 and 4 before adding the new step 3

    history.length = this.indexValue
    history.push(this.state)

    this.history = history
  }

  displayState () {
    this.crumbsTarget.innerHTML = ''

    this.history.forEach((step, index) => {
      if (index <= this.indexValue) {
        this.addStep(step, index < this.indexValue)
      }
    })
  }

  addStep (attributes, isPastState) {
    const template = isPastState ? this.pastTarget : this.currentTarget
    const crumb = template.content.cloneNode(true)

    const wrapper = crumb.firstElementChild
    wrapper.title = attributes.title
    wrapper.href = isPastState ? attributes.url : null

    const content = wrapper.querySelector('span')
    content.title = attributes.title
    content.textContent = attributes.title

    this.crumbsTarget.appendChild(crumb)
  }

  valid () {
    return this.idValue !== '' && this.indexValue < this.history.length + 1
  }

  /* ==== GETTERS & SETTERS */
  get state () {
    const title = this.titleValue || document.getElementsByTagName('title')[0].innerHTML
    return { title: title, url: window.location.href }
  }

  get history () {
    return JSON.parse(sessionStorage.getItem(`breadcrumbs-${this.idValue}`)) || []
  }

  set history (history) {
    sessionStorage.setItem(`breadcrumbs-${this.idValue}`, JSON.stringify(history))
  }
}
