import { Controller } from '@hotwired/stimulus'
import Sortable from 'sortablejs'

export default class extends Controller {
  static values = {
    draggable: String,
    disabled: { type: Boolean, default: false },
    auto: { type: Boolean, default: true }
  }

  connect () {
    this.sortable = Sortable.create(this.element, {
      animation: 150,
      handle: '.draggable-icon',
      draggable: this.draggableValue,
      disabled: this.disabledValue
    })

    if (this.autoValue) this.sortable.options.onEnd = this.end.bind(this.element)
  }

  end (form) {
    form.target.submit()
  }
}
