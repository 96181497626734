import { Controller } from '@hotwired/stimulus'
import Util from '../../lib/util'

export default class extends Controller {
  // ============================================================================
  // ACTIONS
  // ============================================================================

  /*
   * Takes the event of an element, and executes in another
   */
  transmit (event) {
    const target = event.currentTarget.dataset.target
    this.fire(document.querySelector(target), event.type)
  }

  /*
   * Removes an element from the DOM
   */
  remove (event) {
    const target = event.currentTarget.dataset.remove || 'this'
    this.chooseTarget(event.currentTarget, target).remove()
  }

  /*
   * Toggles the specified attribute of the target
   */
  toggleAttribute (event) {
    const target = event.currentTarget.dataset.target
    const attribute = event.currentTarget.dataset.attribute || 'disabled'

    const element = this.chooseTarget(event.currentTarget, target)
    element.toggleAttribute(attribute)
  }

  /*
   * Sets the attribute of an element
   */
  setAttribute (event) {
    const target = event.currentTarget.dataset.target
    const attribute = event.currentTarget.dataset.attribute || 'value'
    const element = this.chooseTarget(event.currentTarget, target)
    let value = event.currentTarget.dataset.value || event.currentTarget.value

    if (value === 'true') {
      value = true
    } else if (value === 'false') {
      value = false
    }

    element[attribute] = value
    if (attribute === 'value') this.fire(element, 'input')
  }

  /*
   * Toggles the specified class of the target
   */
  toggleClass (event) {
    const element = event.currentTarget
    const klass = element.dataset.class || 'hidden'
    const { target, required, timeout } = element.dataset

    target.split(',').forEach(target => {
      const tmp = this.chooseTarget(event.currentTarget, target)

      if (required === 'true') {
        tmp.classList.toggle(klass, element.value === '')
      } else if (required) {
        tmp.classList.toggle(klass, element.value !== required)
      } else {
        tmp.classList.toggle(klass)
      }

      // Toggle back after timeout (if timeout is defined)
      if (timeout) {
        const timeoutMs = parseInt(timeout, 10)

        setTimeout(() => {
          tmp.classList.toggle(klass)
        }, timeoutMs)
      }
    })
  }

  /*
   * Toggles a specified class of a target element based on the value of the trigger element
   */
  toggleClassByValue (event) {
    const element = event.currentTarget
    const target = element.dataset.target
    const selectedValue = element.value
    const targetValue = element.dataset.value
    const klass = element.dataset.class || 'hidden'

    target.split(',').forEach(target => {
      const tmp = this.chooseTarget(event.currentTarget, target)
      if (selectedValue === targetValue) {
        tmp.classList.remove(klass)
      } else {
        tmp.classList.add(klass)
      }
    })
  }

  /*
   * Removes the specified class of the target
   */
  removeClass (event) {
    const element = event.currentTarget
    const klass = element.dataset.class || 'hidden'
    const target = element.dataset.utilTarget || element.dataset.target

    target.split(',').forEach(target => {
      const tmp = this.chooseTarget(event.currentTarget, target)
      tmp.classList.remove(klass)
    })
  }

  removeClassForAll(event) {
    const element = event.currentTarget
    const klass = element.dataset.class || 'hidden'
    const targets = document.querySelectorAll(element.dataset.utilTarget || element.dataset.target)

    targets.forEach(target => {
      target.classList.remove(klass)
    })
  }

  /*
   * Adds the specified class of the target
   */
  addClass (event) {
    const element = event.currentTarget
    const klass = element.dataset.class || 'hidden'
    const target = element.dataset.utilTarget || element.dataset.target

    target.split(',').forEach(target => {
      const tmp = this.chooseTarget(event.currentTarget, target)
      tmp.classList.add(klass)
    })
  }

  addClassForAll(event) {
    const element = event.currentTarget
    const klass = element.dataset.class || 'hidden'
    const targets = document.querySelectorAll(element.dataset.utilTarget || element.dataset.target)

    targets.forEach(target => {
      target.classList.add(klass)
    })
  }

  /*
   * Performs a smooth scroll to an element
   */
  scrollToElement (event) {
    const element = event.currentTarget
    const target = element.dataset.target || element.dataset.target
    const tmp = this.chooseTarget(event.currentTarget, target)

    setTimeout(() => Util.scrollToElement(tmp), 0)
  }

  // ============================================================================
  // OTHER
  // ============================================================================
  /*
   * Dispatches an event on the object
   */
  fire (obj, name) {
    const event = new CustomEvent(name, {
      bubbles: true,
      cancelable: true,
      detail: null
    })
    obj.dispatchEvent(event)
    return !event.defaultPrevented
  }

  // ============================================================================
  // INTERNAL USAGE
  // ============================================================================
  chooseTarget (element, target) {
    target = target.trim()

    if (!target) {
      return element
    } else if (target === 'parent') {
      return element.parentElement
    } else if (target === 'this') {
      return element
    } else if (target.indexOf('closest') !== -1) {
      return element.closest(target.split(' ')[1])
    } else {
      return document.querySelector(target)
    }
  }

  /* Scroll to website top */
  scrollTopWebsite () {
    document.querySelector('[data-website-top]').scrollTop = 0
  }
}
