import { Controller } from '@hotwired/stimulus'
import Text from '../../lib/text'
import Dom from '../../lib/dom'

export default class extends Controller {
  static targets = ['name', 'number', 'expiration', 'cvv', 'hash', 'token', 'error', 'submit']
  static values = { 'payment': Number, 'user': String, 'session': Number }

  connect () {
    this.blockSensitiveParameters()
  }

  generate (e) {
    if (!this.validate()) return

    Dom.fire(this.submitTarget, 'generating', null, { bubbles: true })

    fetch(`/api/tuna/${this.paymentValue}/new_session?email=${this.email}`)
      .then(response => response.json())
      .then(response => {
        if (response.status === -1) {
          this.handleError(null, response.message)
        } else {
          const token = response.sessionId
          const tokenizer = Tuna(token).tokenizator()

          tokenizer.generate(this.card)
            .then(response => this.submit(token, response.token))
            .catch(error => this.handleError(error))
        }
      }).catch(error => this.handleError(error))
  }

  submit (token, hash) {
    this.tokenTarget.value = token
    this.hashTarget.value = hash
    this.blockSensitiveParameters()

    Dom.fire(document.querySelector('form'), 'submit', null, { bubbles: true })
  }

  handleError (error, message = null) {
    error = new Error(`Tuna card error at payment ${this.paymentValue}: ${error}`)
    console.log(error)

    message ||= 'O pagamento foi recusado. Certifique-se que você está usando um cartão de crédito das bandeiras Visa, Mastercard ou Amex, e que os dados fornecidos estão corretos.'
    this.errorTarget.querySelector('p').textContent = message

    this.errorTarget.classList.remove('hidden')
    Dom.fire(this.submitTarget, 'rejected', null, { bubbles: true })

    fetch(`/api/tuna/${this.paymentValue}/errored?error=${error}`, { method: 'DELETE' })
  }

  validate () {
    let valid = true
    valid = valid & this.validateField(this.nameTarget)
    valid = valid & this.validateField(this.numberTarget, 19)
    valid = valid & this.validateField(this.expirationTarget, 5)
    valid = valid & this.validateField(this.cvvTarget)
    return valid
  }

  validateField (input, length) {
    let valid = true
    valid = valid && Text.isPresent(input.value)
    valid = valid && (!length || input.value.length === length)

    if (!valid) {
      input.classList.add('field_with_errors')
      input.nextElementSibling.classList.add('field_with_errors')
    }

    return valid
  }

  blockSensitiveParameters () {
    this.nameTarget.removeAttribute('name')
    this.numberTarget.removeAttribute('name')
    this.expirationTarget.removeAttribute('name')
    this.cvvTarget.removeAttribute('name')
  }

  toggleElement (element, force) {
    element.disabled = !force
    element.classList.toggle('disabled', !force)
  }

  get card () {
    return {
      cardHolderName: this.nameTarget.value,
      cardNumber: this.numberTarget.value.replace(/ /g, ''),
      expirationMonth: Number(this.expirationTarget.value.substring(0, 2)),
      expirationYear: Number(this.expirationTarget.value.substring(3, 5)) + 2000,
      cvv: this.cvvTarget.value,
      singleUse: true
    }
  }

  get email () {
    const input = document.querySelector('[name="payment[email]"]')
    return input ? input.value : ''
  }
}
