import Parser from './parser'
import Formatter from './formatter'
import Text from './text'

const Validator = {
  assert (format, value) {
    return !format || format === 'text' || Validator[format](value)
  },

  alphanumeric (value) {
    return /^[a-z0-9]+$/i.test(value)
  },

  alphanumeric_and_spaces (value) {
    return /^[a-z0-9 ]+$/i.test(value)
  },

  bank_account (value) {
    return /^[0-9]{1,18}-[a-z0-9]{1,2}$/i.test(value)
  },

  birthdate (value) {
    const parsed = Parser.date(value)
    return !!Parser.date(value) && parsed > new Date(1930, 0, 1) && parsed < new Date()
  },

  adult_date (value) {
    const parsed = Parser.date(value)
    return !!Parser.date(value) && parsed > new Date(1900, 0, 1) && parsed < new Date() && new Date().getFullYear() - parsed.getFullYear() > 18
  },

  cpf_cnpj (value) {
    switch (Formatter.numbers(value).length) {
      case 11:
        return Validator.cpf(value)
      case 14:
        return Validator.cnpj(value)
      default:
        return false
    }
  },

  cpf (value) {
    const cpf = Formatter.numbers(value)
    const digits = cpf.substr(0, 9).split('')

    if (digits.every(num => num === digits[0])) {
      return false
    }

    digits.push(mod11(digits, 10))
    digits.push(mod11(digits, 11))

    return digits.join('') === cpf

    function mod11 (digits, weight) {
      const mod = digits.reduce((total, i) => total + Number(i) * weight--, 0) % 11
      return mod < 2 ? 0 : 11 - mod
    }
  },

  cnpj (value) {
    const cnpj = Formatter.numbers(value)
    const digits = cnpj.substr(0, 12).split('')

    digits.push(mod11(digits, [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2]))
    digits.push(mod11(digits, [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2]))

    return digits.join('') === cnpj

    function mod11 (digits, weights) {
      const mod = digits.reduce((total, i) => total + Number(i) * weights.shift(), 0) % 11
      return mod < 2 ? 0 : 11 - mod
    }
  },

  credit_card (value) {
    return /^\d{4} \d{4} \d{4} \d{4}$/i.test(value)
  },

  credit_card_name (value) {
    return /^[A-Z\s]+$/i.test(value)
  },

  credit_card_month_year (value) {
    const parsed = Parser.credit_card_month_year(value)
    return !!Parser.credit_card_month_year(value) && parsed > new Date()
  },

  decimal (value) {
    return /^(\d{1,3}\.)*\d{1,3},\d{2}$/i.test(value)
  },

  date (value) {
    return !!Parser.date(value)
  },

  email (value) {
    const email = (value || '').trim()
    return /^([\w+\-]\.?)*[a-z0-9\_]+@[a-z\d\-]+(\.[a-z]+)*\.[a-z]+$/i.test(email)
  },

  email_phone (value) {
    if (value.includes('@')) {
      return Validator.email(value)
    } else {
      return Validator.phone(value)
    }
  },

  full_name (value) { // Let's ignore that some people have two names. Premmiar does it and this is for them.
    return /\D{2,}\s\D{2,}/i.test(value)
  },

  negative_number (value) {
    return !isNaN(value)
  },

  letters (value) {
    return value.length === Formatter.letters(value).length
  },

  number (value) {
    return value.length === Formatter.numbers(value).length
  },

  numbers (value) {
    return Validator.number(value)
  },

  phone (value) {
    const tmp = Formatter.numbers(value)
    return tmp.length === 10 || tmp.length === 11
  },

  international_phone (value) {
    return /^(\+|00)[1-9][0-9 \-\(\)\.]{7,}$/.test(value)
  },

  auto_phone (value) {
    return Formatter.numbers(value).length <= 11 ? Validator.phone(value) : Validator.international_phone(value)
  },

  modern_auto_phone (value) {
    return Validator.auto_phone(value)
  },

  modern_brazilian_format (val) {
    return this.auto_phone(val)
  },

  sku (value) {
    return /^[A-Z]{3}-[A-Z]{5}-[0-9]{9}-[A-Z]{2}$/.test(value)
  },

  slug (value) {
    return value === Text.parameterize(value)
  },

  url (value) {
    return /[a-z0-9\-:/.]/.test(value)
  },

  zipcode (value) {
    return /^\d{5}-\d{3}$/i.test(value)
  }
}

export default Validator
