/* eslint-disable */

require('eventlistener-polyfill')
import smoothscroll from 'smoothscroll-polyfill'

export default {
  start () {
    this.forEach()
    smoothscroll.polyfill()
  },

  forEach () {
    if (window.NodeList && !NodeList.prototype.forEach) {
      NodeList.prototype.forEach = function (callback, thisArg) {
        thisArg = thisArg || window
        for (var i = 0; i < this.length; i++) {
          callback.call(thisArg, this[i], i, this)
        }
      }
    }

    if (window.HTMLCollection && !HTMLCollection.prototype.forEach) {
      HTMLCollection.prototype.forEach = function (callback, thisArg) {
        thisArg = thisArg || window
        for (var i = 0; i < this.length; i++) {
          callback.call(thisArg, this[i], i, this)
        }
      }
    }
  },
}
