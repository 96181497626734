import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static classes = ['intersect']

  connect () {
    this.observer = new IntersectionObserver(this.intersected.bind(this), {
      threshold: 1
    })
    this.observer.observe(this.element)
  }

  disconnect () {
    this.observer.disconnect()
  }

  intersected (entries) {
    requestAnimationFrame(() => {
      entries.forEach((entry) => {
        this.element.firstElementChild.classList.toggle(this.intersectClass, !entry.isIntersecting)
      })
    })
  }
}
