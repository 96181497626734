const Enums = {
  months: {
    '00': '',
    '01': 'Janeiro',
    '02': 'Fevereiro',
    '03': 'Março',
    '04': 'Abril',
    '05': 'Maio',
    '06': 'Junho',
    '07': 'Julho',
    '08': 'Agosto',
    '09': 'Setembro',
    '10': 'Outubro',
    '11': 'Novembro',
    '12': 'Dezembro'
  },

  weekdays: [
    'Domingo',
    'Segunda-feira',
    'Terça-feira',
    'Quarta-feira',
    'Quinta-feira',
    'Sexta-feira',
    'Sábado'
  ],

  hours: {
    '00': 'Zero',
    '01': 'Uma',
    '02': 'Duas',
    '03': 'Três',
    '04': 'Quatro',
    '05': 'Cinco',
    '06': 'Seis',
    '07': 'Sete',
    '08': 'Oito',
    '09': 'Nove',
    '10': 'Dez',
    '11': 'Onze',
    '12': 'Doze',
    '13': 'Treze',
    '14': 'Quatorze',
    '15': 'Quinze',
    '16': 'Dezesseis ',
    '17': 'Dezessete',
    '18': 'Dezoito',
    '19': 'Dezenove',
    '20': 'Vinte',
    '21': 'Vinte e Uma',
    '22': 'Vinte e Duas',
    '23': 'Vinte e Três',
    '24': 'Vinte e Quatro'
  }

}

export default Enums
