import { Controller } from '@hotwired/stimulus'

const directionMap = {
  left: 'scrollLeft',
  top: 'scrollTop'
}

export default class extends Controller {
  static targets = ['reference', 'scrollable']
  static values = { direction: String, autoScroll: Boolean }

  connect () {
    if (this.autoScrollValue) {
      this.scroll()
    }
  }

  scroll () {
    const method = directionMap[this.directionValue]

    if (method) {
      this[method]()
    } else {
      console.error(`Invalid direction value: ${this.directionValue}`)
    }
  }

  scrollLeft () {
    const targetOffset = this.referenceTarget.offsetLeft;
    this.scrollableTarget.scrollLeft = targetOffset;
  }

  scrollTop () {
    const targetOffset = this.referenceTarget.offsetTop;
    this.scrollableTarget.scrollTop = targetOffset;
  }
}
