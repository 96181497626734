import { Controller } from '@hotwired/stimulus'
import Dom from '../../lib/dom'

export default class extends Controller {
  static targets = ['payment', 'submit', 'error', 'main', 'fallback']
  static values = {
    clientSecret: String,
    publishableKey: String,
    email: String,
    name: String,
    phone: String,
    city: String,
    country: String,
    line1: String,
    line2: String,
    postalCode: String,
    state: String,
    syncUrl: String
  }

  connect () {
    var checkExist = setInterval(() => {
      if (typeof Stripe !== 'undefined') {
        this.stripe = Stripe(this.publishableKeyValue)
        this.elements = this.stripe.elements(this.options)

        const paymentElement = this.elements.create('payment')
        paymentElement.mount(this.paymentTarget)
        clearInterval(checkExist)
      }
    }, 100)
  }

  installmentsChanged (e) {
    const installments = e.currentTarget.value
    this.mainTarget.classList.toggle('hidden', installments !== '1')
    this.fallbackTarget.classList.toggle('hidden', installments === '1')
  }

  async generate (e) {
    Dom.fire(this.submitTarget, 'generating', null, { bubbles: true })

    fetch(this.syncUrlValue).then(response => {
      if (!response.ok) throw new Error('Something went wrong.')
    }).catch(error => this.handleError(error))

    const { error } = await this.stripe.confirmPayment({
      elements: this.elements,
      confirmParams: {
        return_url: this.element.action,
        payment_method_data: {
          billing_details: {
            address: {
              city: this.cityValue,
              country: this.countryValue,
              line1: this.line1Value,
              line2: this.line2Value,
              postal_code: this.postal_codeValue,
              state: this.stateValue
            },
            email: this.emailValue,
            name: this.nameValue,
            phone: this.phoneValue
          }
        }
      }
    })

    this.handleError(error)
  }

  handleError (error) {
    if (error.type === 'card_error' || error.type === 'validation_error') {
      this.errorTarget.firstElementChild.textContent = error.message
    } else {
      this.errorTarget.firstElementChild.textContent = 'Um erro inesperado aconteceu. Atualize essa página e tente novamente.'
    }

    this.errorTarget.classList.remove('hidden')
    Dom.fire(this.submitTarget, 'rejected', null, { bubbles: true })
  }

  get options () {
    return {
      clientSecret: this.clientSecretValue,
      locale: 'pt-br',
      appearance: {
        theme: 'stripe',
        labels: 'floating'
      }
    }
  }
}
