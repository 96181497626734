import { Controller } from '@hotwired/stimulus'

import loadImage from 'blueimp-load-image/js/load-image'
import Cropper from 'cropperjs'

export default class extends Controller {
  static targets = [
    'x', 'y', 'rotate', 'width', 'height',
    'file', 'photo', 'uploadWrapper', 'error', 'chooseButton', 'restartButton',
    'saveButton', 'controls'
  ]

  connect () {
    this.data.set('photo', this.photoTarget.src)
    this.toggleElements('initialize')
  }

  change (e) {
    this.toggleElement(this.uploadWrapperTarget, true)
    const file = e.target.files[0]
    if (!!file && (/image\/(jpg|jpeg|png|webp|gif)/.test(file.type))) {
      this.toggleElements('loading')
      loadImage(file, (img) => {
        this.photoTarget.src = img.src
        this.initEditor()
      }, { noRevoke: true })
    } else {
      this.toggleElements('error')
      e.target.value = ''
    }
  }

  restart () {
    this.cropper?.destroy()
    this.cropper = null
    this.fileTarget.value = ''
    this.photoTarget.src = this.data.get('photo')
    this.toggleElements('restart')
  }

  edit () {
    this.toggleElements('loading')
    this.initEditor()
  }

  initEditor () {
    this.cropper = new Cropper(this.photoTarget, {
      aspectRatio: (720 / 1024),
      viewMode: 1,
      autoCropArea: 1,
      cropBoxResizable: false,
      cropBoxMovable: false,
      dragMode: 'move',
      crop: (e) => {
        if (!this.exifRotate) this.exifRotate = e.detail.rotate

        this.xTarget.value = e.detail.x
        this.yTarget.value = e.detail.y
        this.heightTarget.value = e.detail.height
        this.widthTarget.value = e.detail.width

        // iOS is a fucking moron and all photos are rotated by default. Compensate user rotation by it
        this.rotateTarget.value = e.detail.rotate - this.exifRotate
      },
      ready: () => {
        this.toggleElements('editing')
      }
    })
  }

  hasPhoto () {
    return this.data.get('hasPhoto') === 'true'
  }

  toggleElement (element, force, klass = 'hidden') {
    element.classList.toggle(klass, !force)
  }

  toggleElements (status) {
    switch (status) {
      case 'loading':
        this.toggleElement(this.chooseButtonTarget, false)
        this.uploadWrapperTarget.classList.add('inactive')
        if (this.hasControlsTarget) this.toggleElement(this.controlsTarget, false)
        break
      case 'editing':
        this.uploadWrapperTarget.classList.remove('inactive')
        this.toggleElement(this.restartButtonTarget, true)
        this.toggleElement(this.saveButtonTarget, true)
        break
      case 'error':
        this.toggleElement(this.chooseButtonTarget, false)
        this.toggleElement(this.uploadWrapperTarget, false)
        this.toggleElement(this.errorTarget, true)
        if (this.hasControlsTarget) this.toggleElement(this.controlsTarget, false)
        break
      default:
        this.toggleElement(this.chooseButtonTarget, !this.hasPhoto())
        this.toggleElement(this.uploadWrapperTarget, this.hasPhoto(), 'inactive')
        this.toggleElement(this.errorTarget, false)
        this.toggleElement(this.restartButtonTarget, false)
        this.toggleElement(this.saveButtonTarget, false)
        if (this.hasControlsTarget) this.toggleElement(this.controlsTarget, this.hasPhoto())
    }
  }
}
