import { Controller } from '@hotwired/stimulus'

/*
 * Provides additional functionality for <details> element.
 *
 * 1 - When a page is loaded, if the url contains an anchor, open the corresponding details
 * 2 - When a detail is opened, notify elements inside that they have become visible
 * 3 - Optionally, close other expandable details with a specified class when one is opened
 */
export default class extends Controller {
  static values = { closeable: String }

  /* ==== Life Cycle ==== */
  initialize () {
    this.observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'attributes') {
          this.toggleVisibility()
        }
      })
    })
  }

  connect () {
    this.observer.observe(this.element, { attributes: true })

    if (this.element.id && !this.element.open) {
      this.element.open = this.element.id === this.anchor
    }

    this.element.addEventListener('toggle', () => this.handleToggle())
  }

  /* ==== Support ==== */
  toggleVisibility () {
    this.element.children.forEach(element => {
      if (this.element.open) {
        this.fire(element, 'becameVisible')
      } else {
        this.fire(element, 'becameInvisible')
      }
    })
  }

  handleToggle () {
    if (this.closeableValue && this.element.open) {
      const allCloseable = document.querySelectorAll(`details.expandable.${this.closeableValue}`)
      allCloseable.forEach(closeable => {
        if (closeable !== this.element && closeable.open) {
          closeable.open = false
        }
      })
    }
  }

  fire (obj, name) {
    // Check that the target element is still in the page
    if (!document.body.contains(obj)) return

    const event = new CustomEvent(name, {
      bubbles: true,
      cancelable: true,
      detail: null
    })
    obj.dispatchEvent(event)
    return !event.defaultPrevented
  }

  /* ==== Getters & Setters ==== */
  get anchor () {
    return document.location.toString().split('#')[1]
  }
}
