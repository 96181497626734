import { Controller } from '@hotwired/stimulus'
import Dom from '../../lib/dom'

export default class extends Controller {
  static values = { item: String, price: Number }
  static targets = ['form', 'orderId', 'payerId', 'status', 'email']

  connect () {
    this.initButton()
  }

  initButton () {
    const checkPresence = setInterval(() => {
      if (typeof paypal === 'object' && typeof paypal.Buttons === 'function') {
        clearInterval(checkPresence)
        paypal.Buttons({

          style: {
            color: 'white',
            size: 'responsive',
            shape: 'rect',
            label: 'paypal',
            layout: 'horizontal',
            fundingicons: 'false',
            tagline: 'false',
            height: 55
          },

          createOrder: this.createOrder.bind(this),
          onApprove: this.onApprove.bind(this)
        }).render('#paypal-button-container')
      }
    }, 100)
  }

  createOrder (data, actions) {
    return actions.order.create({
      purchase_units: [{
        description: this.itemValue,
        amount: {
          value: this.priceValue,
          currency_code: 'BRL'
        }
      }],
      application_context: {
        brand_name: 'FestaLab',
        locale: 'pt-BR',
        shipping_preference: 'NO_SHIPPING'
      }
    })
  }

  onApprove (data, actions) {
    document.getElementById('payment-loading-paypal').classList.toggle('hidden')
    document.getElementById('paypal-button').classList.toggle('hidden')

    actions.order.capture().then(details => {
      this.orderIdTarget.value = data.orderID
      this.payerIdTarget.value = data.payerID
      this.statusTarget.value = details.status
      this.emailTarget.value = details.payer.email_address

      Dom.fire(this.formTarget, 'submit', null, { bubbles: true })
    })
  }
}
