import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  getVideo (event) {
    return document.getElementById(event.params.id)
  }

  play (event) {
    const video = this.getVideo(event)
    video.play()
  }

  stop (event) {
    const video = this.getVideo(event)
    video.pause()
    video.currentTime = 0
  }
}
