import Draggable from './lib/draggable'
import Util from '../../../lib/util'
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['text']
  static values = { draggable: Boolean }

  /* == Life Cycle == */
  initialize () {
    this.template = document.querySelector('#template')
    this.overlay = document.querySelector('.setout__overlay')

    this.initWrapper()
    this.initInteraction()
    this.initWorkArounds()

    this.updateLegacyFragment()
  }

  /* == Initialization == */
  initWrapper () {
    this.element.setAttribute('style', 'position:absolute;top:0;left:0;display:flex;')

    this.x = this.x || 0
    this.y = this.y || 0
    this.skewX = this.skewX || 0
    this.skewY = this.skewY || 0
    this.rotate = this.rotate || 0
    this.align = this.align || 'center'
    this.valign = this.valign || 'center'

    const color = this.element.getAttribute('data-color')
    this.element.style.setProperty('--fragment-border', `#${color}`)

    this.updateWrapperStyle()
  }

  initInteraction () {
    if (this.isDraggable) {
      this.draggable = new Draggable(this.element, this.drag.bind(this))
    }
  }

  initWorkArounds () {
    const editable = this.element.querySelector('[contenteditable]')
    if (!editable) return

    editable.addEventListener('keydown', (event) => {
      const keyCode = event.charCode || event.keyCode
      if (keyCode !== 13) return

      event.preventDefault()

      const selection = window.getSelection()
      const range = selection.getRangeAt(0)
      const br = document.createElement('br')

      range.deleteContents()
      range.insertNode(br)

      range.setStartAfter(br)
      range.setEndAfter(br)

      selection.removeAllRanges()
      selection.addRange(range)

      return false
    })
  }

  /* == Interaction == */
  drag (event) {
    this.x += event.dx / this.scaleWidth
    this.y += event.dy / this.scaleHeight

    this.updateWrapperStyle()
  }

  updateWrapperStyle () {
    const align = { left: 'flex-start', center: 'center', right: 'flex-end' }
    const valign = { top: 'flex-start', center: 'center', bottom: 'flex-end' }

    Object.assign(this.element.style, {
      width: `${this.width}px`,
      height: `${this.height}px`,
      transform: `translate(${this.x}px, ${this.y}px) rotate(${this.rotate}deg) skew(${this.skewX}deg, ${this.skewY}deg)`, // Transform is done right to left. Do not change order.
      justifyContent: align[this.align],
      alignItems: valign[this.valign],
      textAlign: this.align
    })
  }

  /* == Update legacy fragments that contain defective code */
  updateLegacyFragment () {
    const constrainContent = this.splicer === 'photo'

    Object.assign(this.textTarget.style, {
      width: constrainContent ? '100%' : null,
      maxHeight: constrainContent ? '100%' : null
    })
  }

  /* == Getters & Setters == */
  set x (value) {
    this.element.setAttribute('data-x', value.toFixed(0))
  }

  get x () {
    return Number(this.element.dataset.x)
  }

  set y (value) {
    this.element.setAttribute('data-y', value.toFixed(0))
  }

  get y () {
    return Number(this.element.dataset.y)
  }

  set skewX (value) {
    this.element.setAttribute('data-skew-x', value)
  }

  get skewX () {
    return Number(this.element.dataset.skewX)
  }

  set skewY (value) {
    this.element.setAttribute('data-skew-y', value)
  }

  get skewY () {
    return Number(this.element.dataset.skewY)
  }

  set rotate (value) {
    this.element.setAttribute('data-rotate', value)
  }

  get rotate () {
    return Number(this.element.dataset.rotate)
  }

  set width (value) {
    this.element.setAttribute('data-width', value.toFixed(0))
  }

  get width () {
    return Number(this.element.dataset.width)
  }

  set height (value) {
    this.element.setAttribute('data-height', value.toFixed(0))
  }

  get height () {
    return Number(this.element.dataset.height)
  }

  get scaleWidth () {
    return (this.template.offsetWidth / this.overlay.offsetWidth)
  }

  get scaleHeight () {
    return (this.template.offsetHeight / this.overlay.offsetHeight)
  }

  set align (value) {
    this.element.setAttribute('data-align', value)
  }

  get align () {
    return this.element.dataset.align
  }

  set valign (value) {
    this.element.setAttribute('data-valign', value)
  }

  get valign () {
    return this.element.dataset.valign
  }

  get isDraggable () {
    return !this.hasDraggableValue || this.draggableValue
  }
}
