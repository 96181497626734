import { DirectUpload } from '../rails/activestorage'

export default class {
  constructor (input) {
    this.input = input

    this.submit = input.form.querySelector('.btn-submit')
    this.progress = this.submit.querySelector('.btn-submit-progress')
    this.loader = this.submit.querySelector('.btn-submit-loader')

    this.totalSize = 0
    this.loaded = {}
  }

  upload () {
    const promises = Array.from(this.input.files).map(file => {
      return new Promise((resolve, reject) => {
        const url = this.input.dataset.directUploadUrl
        this.submit.classList.add('btn-submit-uploading')
        this.totalSize += file.size

        this.directUpload(this.submit, this.input, file, url, resolve, reject, this)
      }).catch(() => {
        // Do nothing. Yes nothing, I just want Sentry to shut up.
      })
    })

    return Promise.allSettled(promises).catch(() => {
      // Do nothing. Yes nothing, I just want Sentry to shut up.
    })
  }

  directUpload (submit, input, file, url, resolve, reject, caller) {
    new DirectUpload(file, url, caller).create((error, blob) => {
      if (error) {
        submit.classList.remove('btn-submit-uploading')
        reject(error)
      } else {
        const hiddenField = document.createElement('input')
        hiddenField.setAttribute('type', 'hidden')
        hiddenField.setAttribute('value', blob.signed_id)
        hiddenField.name = input.name
        input.form.appendChild(hiddenField)
        resolve('Upload done')
      }
    })
  }

  directUploadWillStoreFileWithXHR (request) {
    request.upload.addEventListener('progress',
      event => this.directUploadDidProgress(event))
  }

  directUploadDidProgress (event) {
    this.loaded[event.total] = event.loaded // No ids, so let's do something fugly and use the file size as the id
    const totalLoaded = Object.values(this.loaded).reduce((sum, x) => sum + x)

    console.log(this.loaded)
    console.log(totalLoaded / this.totalSize)
    this.progress.style.width = `${totalLoaded / this.totalSize * 100}%`
  }
}
