import Resizer from './resizer'
import Text from './text'
import Util from './util'
import Dom from './dom'

const Binder = {

  /*
   * Displays the target if the source has content
   * @param {HTMLElement} source - The element to check for a value
   * @param {HTMLElement} target - The element to display or hide
   */
  conditional (source, target) {
    Util.delegate(document, source, 'input', function (e) {
      const targetElement = document.getElementById(target)
      if (!targetElement) return

      Binder.chooseTargetElement(targetElement).classList.toggle('hidden', !e.target.value.trim())
    })

    Binder.clickBind(target, source)
  },

  /*
* Displays the target or the targetConditional whether the source has content
* @param {HTMLElement} source - The element to check for a value
* @param {HTMLElement} targetConditional - A conditional element to display or hide, opposilly to the target
* @param {HTMLElement} target - The element to display or hide
*/
  conditional_target (source, targetConditional, target) {
    Util.delegate(document, source, 'input', function (e) {
      const targetElement = document.getElementById(target)
      const conditionalElement = document.getElementById(targetConditional)
      const sourceElement = document.querySelector(source)

      if (!targetElement) return

      if (!conditionalElement) {
        return
      } else {
        if (!sourceElement.value && conditionalElement.firstElementChild.className === 'hidden') {
          Binder.chooseTargetElement(conditionalElement).classList.toggle('hidden', false)
        } else {
          Binder.chooseTargetElement(conditionalElement).classList.toggle('hidden', true)
        }
      }

      Binder.chooseTargetElement(targetElement).classList.toggle('hidden', !e.target.value.trim())
    })

    Binder.clickBind(target, source)
  },

  /*
  * Copies the value of multiple sources to the target, passing each one through their callback
  * @param {array} sources - An array of objects containing the 'name' and 'callback' properties.
  * @param {HTMLElement} target - The element to copy to
  * @param {string} separators - The string or array to use to join the sources together
  * @param {function} callback - A final callback to put the value through
  */
  custom (sources, target, separators, callback) {
    sources.forEach(function (source) {
      const event = source.event || 'input'

      Util.delegate(document, source.selector, event, function () {
        const targetElement = document.getElementById(target)
        if (!targetElement) return

        let result = joinSources(sources, separators)
        if (callback) result = callback(result)
        Binder.setText(targetElement, result)
      })
    })

    Binder.clickBind(target, sources[0].selector)

    function joinSources (sources, separators) {
      const arr = []

      sources.forEach(function (source) {
        const element = document.querySelector(source.selector)
        const value = Binder.getValue(element)
        const formatted = source.callback ? source.callback(value) : value
        if (formatted.length > 0) arr.push(formatted.toString())
      })

      return join(arr, separators)
    }

    function join (arr, separators) {
      if (arr.length === 0) return ''

      if (typeof separators === 'string') return arr.join(separators)

      if (separators.length < arr.length - 1) return ''

      const tmp = separators.slice()
      tmp.reverse()

      const result = []
      arr.forEach(function (item) {
        const separator = tmp.pop()
        item = item.trim()

        if (item.length > 0) {
          result.push(item)
          result.push(separator)
        }
      })

      return result.join('')
    }
  },

  /*
   * Copies the source value to the target text after passing it through the callback
   * @param {HTMLElement} source - The element to copy from
   * @param {HTMLElement} target - The element to copy to
   * @param {function} callback - The function that will format the value of the source element
   */
  formatted (source, target, callback) {
    Util.delegate(document, source, 'input', function (e) {
      const targetElement = document.getElementById(target)
      if (!targetElement) return

      Binder.setText(targetElement, callback(Binder.getValue(e.target)))
    })

    Binder.clickBind(target, source)
  },

  /*
   * Switches span visibility according to gender
   * @param {HTMLElement} source - The element containing the gender choice
   * @param {HTMLElement} target - The element contaning the gender information to be chosen.
   */
  gendered (source, target) {
    Util.delegate(document, source, 'input', function (e) {
      const targetElement = document.getElementById(target)
      if (!targetElement) return

      const males = targetElement.querySelectorAll('[data-gender="male"]')
      const females = targetElement.querySelectorAll('[data-gender="female"]')
      const val = Binder.getValue(e.target)

      if (val === 'male') {
        males.forEach(function (ev) {
          ev.classList.remove('hidden')
        })
        females.forEach(function (ev) {
          ev.classList.add('hidden')
        })
      } else {
        males.forEach(function (ev) {
          ev.classList.add('hidden')
        })
        females.forEach(function (ev) {
          ev.classList.remove('hidden')
        })
      }
    })

    Binder.clickBind(target, source)
  },

  /*
   * Gets the binding value from the specified element
   */
  getRadioValue (element) {
    const checked = document.querySelector('[name="' + element.name + '"]:checked')
    return (checked || {}).value || ''
  },

  /*
   * Gets the binding value from the specified element
   */
  getValue (element) {
    const value = element.value || (element.dataset || {}).placeholder || element.placeholder || ''
    return value.toString().trim()
  },

  /*
   * Copies the value of multiple sources to the target's text
   * @param {HTMLElement} sources - The element to copy from
   * @param {HTMLElement} target - The element to copy to
   * @param {string} separator - The string to use to join the sources together
   */
  join (sources, target, separator) {
    sources.forEach(function (source) {
      Util.delegate(document, source, 'input', function () {
        const targetElement = document.getElementById(target)
        if (!targetElement) return

        separator = Binder.chooseSeparator(targetElement, separator)
        Binder.setText(targetElement, joinSources(sources, separator))
      })
    })

    function joinSources (sources, separator) {
      const arr = []

      sources.forEach(function (source) {
        const element = document.querySelector(source)
        const value = Binder.getValue(element)
        if (value.length > 0) arr.push(value.toString().trim())
      })

      return arr.join(separator)
    }

    Binder.clickBind(target, sources[0])
  },

  /*
   * Switches visibility according to option chosen
   * @param {HTMLElement} source - The element containing the classification choice
   * @param {HTMLElement} target - The element contaning the classification information to be chosen.
   */
  optionPicker (source, target) {
    Util.delegate(document, source, 'input', function (e) {
      const targetElement = document.getElementById(target)
      if (!targetElement) return

      const option = Binder.getValue(e.target)
      targetElement.querySelectorAll('[data-option]').forEach(function (element) {
        if (element.dataset.option === option) {
          element.classList.remove('hidden')
        } else {
          element.classList.add('hidden')
        }
      })

      Resizer.allFonts()
    })

    Binder.clickBind(target, source)
  },

  /*
   * Displays the target if the source has content
   * @param {HTMLElement} source - The element to check for a value
   * @param {HTMLElement} conditionalSource - The element to check for a value
   * @param {HTMLElement} target - The element to display or hide
   */
  placeholder (source, conditionalSource, target) {
    Util.delegate(document, source, 'input', () => {
      setPlaceholder(source, conditionalSource, target)
    })

    Util.delegate(document, conditionalSource, 'input', () => {
      setPlaceholder(source, conditionalSource, target)
    })

    function setPlaceholder (source, conditional, target) {
      const targetElement = document.getElementById(target)
      if (!targetElement) return

      if (document.querySelector(source).value !== '') return

      if (document.querySelector(conditionalSource).value !== '') {
        Binder.setText(targetElement, '')
      } else {
        Binder.setText(targetElement, '', true)
      }
    }
  },

  /*
   * Switches elements between their singlar and plural form according to the number of birthday boys/girls
   * @param {HTMLElement} source - The element containing the age
   */
  pluralizable (source) {
    Util.delegate(document, source, 'input', function (e) {
      const val = Binder.getValue(e.target).match(/\d+/g)
      const form = val && val.length > 1 ? 'plural' : 'singular'

      document.querySelectorAll('[data-pluralizable]').forEach(function (ev) {
        if (form === ev.dataset.pluralizable) {
          ev.classList.remove('hidden')
        } else {
          ev.classList.add('hidden')
        }
      })
    })
  },

  /*
   * Copies the source value to the target's text
   * @param {HTMLElement} source - The element to copy from
   * @param {HTMLElement} target - The element to copy to
   */
  raw (source, target) {
    Util.delegate(document, source, 'input', function (e) {
      const targetElement = document.getElementById(target)
      if (!targetElement) return

      Binder.setText(targetElement, Binder.getValue(e.target))
    })

    Binder.clickBind(target, source)
  },

  /*
   * Sets the value of an element
   */
  setText (element, value, force = false) {
    const target = Binder.chooseTargetElement(element)
    const result = Binder.formatValue(target, value, force)

    if (target.tagName === 'IMG') {
      const src = value.length > 0 ? Text.urlize(result) : 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='
      target.src = src
    } else if (target.tagName === 'INPUT') {
      target.value = result
      Dom.fire(target, 'input', null, { bubbles: true })
    } else {
      target.innerHTML = result.replace(/-/g, '&#8209;')
      Resizer.font(target)
    }
  },

  /*
   * If the target element is clicked, the source element is focused
   */
  clickBind (source, target) {
    Util.delegate(document, '#' + source, 'click', function (e) {
      if (e.target.tagName === 'INPUT') return

      const to = Util.getPosition(document.querySelector(target))
      window.scroll({
        top: to.y - 15,
        left: 0,
        behavior: 'smooth'
      })
    })
  },

  chooseTargetElement (element) {
    const children = element.querySelectorAll('span, img')
    return children.length > 0 ? children[0] : element
  },

  chooseSeparator (element, separator) {
    const target = Binder.chooseTargetElement(element)
    return target.dataset.binderSeparator || separator
  },

  formatValue (element, value, force) {
    if (force) return value
    if (value.length === 0) return element.dataset.binderPlaceholder || ''

    const prefix = element.dataset.binderPrefix || ''
    const suffix = element.dataset.binderSuffix || ''
    const formatted = prefix + value + suffix

    const pad = element.dataset.binderPadding || ''
    return pad.length === 0 ? formatted : formatted.padStart(pad.length, pad.charAt(0))
  }
}

export default Binder
