import { Controller } from '@hotwired/stimulus'
import Util from '../../lib/util'

export default class extends Controller {
  static targets = ['url', 'message']
  static values = { fallbackUrl: String, title: String, imageUrl: String, rootUrl: String }

  /* == Life cycle == */
  initialize () {
    // this.prepareImageForSharing()
  }

  /* == Actions == */
  chooseSharingMode (e) {
    if (Util.deviceCanShare()) {
      this.prepareForNativeSharing()
    } else {
      this.prepareForAppSharing()
    }
  }

  /* == Logic == */
  prepareImageForSharing () {
    if (!this.imageUrl || !Util.deviceCanShareFiles()) return

    fetch(this.imageUrl).then(res => {
      if (!res.ok) {
        // TODO: Log this result in analytics;
      } else {
        return res.blob()
      }
    }).then(blob => {
      this.imageFile = new File([blob], 'dot.png', { type: 'image/png' })
    }).finally(() => {
    })
  }

  prepareForNativeSharing () {
    try {
      const shareData = {
        title: this.titleValue,
        text: this.message,
        files: this.imageFile ? [this.imageFile] : []
      }
      navigator.share(shareData).then((resolve, reject) => {
        // TODO: Log this result in analytics;
      }).catch(error => {
        if (error.name === 'AbortError') {
          // TODO: Log this error in analytics;
        } else {
          // throw error
        }
        this.prepareForAppSharing()
      })
    } catch (e) {
      this.prepareForAppSharing()
    }
  }

  prepareForAppSharing () {
    let params = ''
    if (this.fallbackUrlValue.includes('?')) {
      params = `&message=${this.message}`
    } else {
      params = `?message=${this.message}`
    }

    Turbo.visit(`${this.fallbackUrlValue}${params}`)
  }

  /* == Getter and Setters == */
  get formattedUrl () {
    if (this.rootUrlValue && this.urlTarget) {
      return `${this.rootUrlValue}${this.urlTarget.value}`
    } else {
      return null
    }
  }

  get message () {
    return this.messageTarget.value
  }

  get imageUrl () {
    /*
     * User is about to share his website, however its image is to big (height, not kbs) for that.
     * If user has paid for an invitation, use its image for sharing, otherwise rely on opengraph for the preview.
     *
     * This replace is a hack because CORS is broken when going through cloudflare
     */
    if (this.imageUrlValue.indexOf('s3.amazonaws.com') !== -1) {
      return this.imageUrlValue || ''
    } else {
      return (this.imageUrlValue || '').replace('https://', 'https://s3-sa-east-1.amazonaws.com/')
    }
  }
}
