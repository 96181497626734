/* eslint-disable */

export default {
  start () {
    // Temporarily disable minimal analytics
    if (!this.trackerId() || !this.storageAvailable('localStorage')) {
      return
    }

    this.trackerId().startsWith("UA") ? this.legacyAnalytics() : this.newAnalytics()
  },

  legacyAnalytics () {
    (function (context, trackingId, options) {
      const history = context.history
      const doc = document
      const nav = navigator || {}
      const storage = localStorage
      const encode = encodeURIComponent
      const pushState = history.pushState
      const typeException = 'exception'
      const generateId = () => Math.random().toString(36)
      const getId = () => {
        if (!storage.cid) {
          storage.cid = generateId()
        }
        return storage.cid
      }
      const serialize = (obj) => {
        var str = []
        for (var p in obj) {
          if (obj.hasOwnProperty(p)) {
            if (obj[p] !== undefined) {
              str.push(encode(p) + '=' + encode(obj[p]))
            }
          }
        }
        return str.join('&')
      }
      const track = (
        type,
        eventCategory,
        eventAction,
        eventLabel,
        eventValue,
        exceptionDescription,
        exceptionFatal
      ) => {
        const url = 'https://www.google-analytics.com/collect'
        const data = serialize({
          v: '1',
          ds: 'web',
          aip: options.anonymizeIp ? 1 : undefined,
          tid: trackingId,
          cid: getId(),
          t: type || 'pageview',
          sd: options.colorDepth && screen.colorDepth ? `${screen.colorDepth}-bits` : undefined,
          dr: doc.referrer || undefined,
          dt: doc.title,
          dl: doc.location.origin + doc.location.pathname + doc.location.search,
          ul: options.language ? (nav.language || '').toLowerCase() : undefined,
          de: options.characterSet ? doc.characterSet : undefined,
          sr: options.screenSize ? `${(context.screen || {}).width}x${(context.screen || {}).height}` : undefined,
          vp: options.screenSize && context.visualViewport ? `${(context.visualViewport || {}).width}x${(context.visualViewport || {}).height}` : undefined,
          ec: eventCategory || undefined,
          ea: eventAction || undefined,
          el: eventLabel || undefined,
          ev: eventValue || undefined,
          exd: exceptionDescription || undefined,
          exf: typeof exceptionFatal !== 'undefined' && !!exceptionFatal === false ? 0 : undefined,
        })

        if (nav.sendBeacon) {
          nav.sendBeacon(url, data)
        } else {
          var xhr = new XMLHttpRequest()
          xhr.open('POST', url, true)
          xhr.send(data)
        }
      }
      const trackEvent = (category, action, label, value) => track('event', category, action, label, value)
      const trackException = (description, fatal) => track(typeException, null, null, null, null, description, fatal)
      history.pushState = function (state) {
        if (typeof history.onpushstate == 'function') {
          history.onpushstate({ state: state })
        }
        setTimeout(track, options.delay || 10)
        return pushState.apply(history, arguments)
      }
      track()
      context.ma = {
        trackEvent,
        trackException
      }
    })(window, this.trackerId(), {
      anonymizeIp: true,
      colorDepth: true,
      characterSet: true,
      screenSize: true,
      language: true
    })
  },

  newAnalytics () {
    function a (trackerId) {
      const r = trackerId
      var t = 'XXXXXX', n, b
      t == 'XXXXXX' ? (n = () => {}) : (n = () => t)
      const s = () => Math.floor(Math.random() * 1e9) + 1, o = () => Math.floor(Date.now() / 1e3), v = () => (sessionStorage._p || (sessionStorage._p = s()), sessionStorage._p), h = () => s() + '.' + o(), d = () => (localStorage.cid_v4 || (localStorage.cid_v4 = h()), localStorage.cid_v4), c = () => (sessionStorage.sid || (sessionStorage.sid = o()), sessionStorage.sid), l = () => {
        if (!sessionStorage._ss) return sessionStorage._ss = '1', sessionStorage._ss
        if (sessionStorage.getItem('_ss') == '1') return void 0
      }, e = '1', u = () => {
        if (sessionStorage.sct) {
          var t = +sessionStorage.getItem('sct') + +e
          sessionStorage.sct = t
        } else sessionStorage.sct = e
        return sessionStorage.sct
      }, a = encodeURIComponent, m = e => {
        var n = [], t
        for (t in e) e.hasOwnProperty(t) && e[t] !== void 0 && n.push(a(t) + '=' + a(e[t]))
        return n.join('&')
      }, f = !1, p = 'https://www.google-analytics.com/g/collect', g = m({ v: '2', tid: r, gtm: n(), _p: v(), sr: (screen.width + 'x' + screen.height).toString(), ul: (navigator.language || void 0).toLowerCase(), cid: d(), _s: '1', dl: document.location.origin + document.location.pathname + document.location.search, dt: document.title || void 0, dr: document.referrer || void 0, sid: c(), sct: u(), seg: '1', en: 'page_view', _ss: l(), _dbg: f ? 1 : void 0 }), i = p + '?' + g
      navigator.sendBeacon ? navigator.sendBeacon(i) : (b = new XMLHttpRequest, b.open('POST', i, !0))
    }

    a(this.trackerId())
  },

  storageAvailable (type) {
    try {
      var storage = window[type],
        x = '__storage_test__'
      storage.setItem(x, x)
      storage.removeItem(x)
      return true
    } catch (e) {
      return false
    }
  },

  trackerId () {
    const analytics = document.querySelector('meta[name=\'analytics\']')
    return analytics && analytics.getAttribute('content')
  }
}
