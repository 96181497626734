import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['checkbox']

  connect () {
    this.observeCheckboxes()
  }

  observeCheckboxes = () => {
    this.checkboxTargets.forEach((target) => {
      target.addEventListener('click', event => {
        this.closeOthers(event.currentTarget)
      })
    })
  }

  closeOthers = (target) => {
    const otherCheckboxes = this.checkboxTargets.filter((element) => element !== target)

    otherCheckboxes.forEach(checkbox => {
      checkbox.checked = false
    })
  }
}
