import { Controller } from '@hotwired/stimulus'
import Formatter from '../../lib/formatter'
import Dom from '../../lib/dom'

export default class extends Controller {
  static targets = ['contactName', 'modal', 'list', 'contactNumber', 'contactButton', 'contactDisplayName']
  connect () {
    const supported = ('contacts' in navigator && 'ContactsManager' in window)
    if (!supported) {
      this.contactButtonTarget.remove()
    }
  }

  async getContacts () {
    try {
      const contacts = await navigator.contacts.select(['tel', 'name'])
      if (!contacts.length || !contacts[0].tel.length || !contacts[0].name.length) {
        return
      }

      Dom.fire(this.element, 'contactsPicker', null, { bubbles: true, cancelable: true })

      if (this.hasContactDisplayNameTarget) {
        this.fillName(contacts[0].name)
      }

      if (contacts[0].tel.length === 1) {
        this.fillContact(contacts[0].tel[0])
      } else {
        this.openModal(contacts[0].tel, contacts[0].name)
      }
    } catch (ex) {
      console.log(ex.toString())
    }
  }

  openModal (phoneNumbers, name) {
    this.contactNameTarget.innerHTML = name
    this.modalTarget.open = true
    const list = this.listTarget
    const contact = list.firstElementChild
    list.innerHTML = ''
    let clone
    for (let i = 0; i < phoneNumbers.length; i++) {
      clone = contact.cloneNode(true)
      clone.firstElementChild.id = 'contact-cta-' + i
      clone.firstElementChild.value = phoneNumbers[i]
      clone.lastElementChild.setAttribute('for', 'contact-cta-' + i)
      clone.lastElementChild.innerHTML = phoneNumbers[i]
      list.appendChild(clone)
    }
  }

  closeModal () {
    const chosenNumber = document.querySelector("input[name='contacts']:checked").value
    this.fillContact(chosenNumber)
    this.modalTarget.open = false
  }

  fillContact (number) {
    const receiverNumber = this.contactNumberTarget
    receiverNumber.value = Formatter.auto_phone(number)
    receiverNumber.classList.add('form-field-filled')
    Dom.fire(receiverNumber, 'input', null)
  }

  fillName (name) {
    const receiverName = this.contactDisplayNameTarget
    receiverName.value = name
    receiverName.classList.add('form-field-filled')
  }
}
