import { Controller } from '@hotwired/stimulus'
import Text from '../../../lib/text'

export default class extends Controller {
  static targets = ['alert']

  validate (event) {
    const sender = document.getElementById('sender')
    const message = document.getElementById('message')
    let errorFieldMessage

    if (sender.textContent.match(/assinatura/i) || Text.isEmpty(sender.textContent)) {
      errorFieldMessage = 'Edite o texto no campo destacado em vermelho no cartão antes de continuar'
      this.errorMessage(sender, event, errorFieldMessage)
    } else if (sender.textContent.length >= 60) {
      errorFieldMessage = 'O campo de assinatura do cartão não pode ter mais que 60 caracteres!'
      this.errorMessage(sender, event, errorFieldMessage)
    } else if (message.innerText.length >= 560) {
      errorFieldMessage = 'O campo de mensagem do cartão não pode ter mais que 560 caracteres!'
      this.errorMessage(message, event, errorFieldMessage)
    } else {
      this.alertTarget.classList.add('hidden')
    }
  }

  errorMessage (element, event, message) {
    event.stopImmediatePropagation()
    this.alertTarget.lastChild.innerText = message
    element.parentElement.classList.add('brd-error')
    this.alertTarget.classList.remove('hidden')
  }
}
